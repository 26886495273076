import { useRouter } from 'next/router';

const TermsAndConditionsSection = () => {
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';
  return isSpanish ? (
    <div className="policies">
      <div className="terms-and-conditions">
        <h1>TÉRMINOS Y CONDICIONES</h1>
        <p>
          Este documento describe los términos y condiciones generales (los
          &quot;Términos y Condiciones&quot;) aplicables al acceso y uso de los
          servicios ofrecidos (&quot;los Servicios&quot;) por TIKIPAL GLOBAL
          SAS, una sociedad por acciones simplificada, constituida de
          conformidad con las leyes de la República de Colombia, identificada
          con NIT No. 901660084-8, cuyas oficinas en el domicilio social se
          encuentran ubicadas en [dirección de TIKIPAL GLOBAL SAS], a través de
          la aplicación móvil/web de su propiedad (&quot;TIKIPAL&quot;).
          Cualquier persona que desee acceder y/o usar la aplicación móvil/web y
          sus servicios podrá hacerlo sujetándose a los Términos y Condiciones,
          junto con todas las demás políticas y principios que rigen a TIKIPAL
          GLOBAL SAS y que son incorporados al presente documento por
          referencia. En consecuencia, todas las visitas y todos los actos y
          contratos que se realicen en TIKIPAL, así como sus efectos jurídicos,
          quedarán regidos por estas reglas y sometidos a la legislación
          aplicable en Colombia.
        </p>
        <p>
          Los Términos y Condiciones y la Política de Tratamiento de Datos
          Personales contenidos en este documento se aplicarán y se entenderán
          como parte integral de todos los actos y contratos que se ejecuten o
          celebren mediante los sistemas de oferta y comercialización
          comprendidos en TIKIPAL entre los Usuarios Finales de TIKIPAL y
          TIKIPAL GLOBAL SAS.
        </p>
        <p>
          El Usuario Final debe leer, entender y aceptar todas las condiciones
          establecidas en los Términos y Condiciones y en la Política de
          Tratamiento de Datos Personales, así como en los demás documentos
          incorporados a los mismos por referencia, previo a su inscripción como
          usuario de TIKIPAL. Si el usuario hiciera uso de TIKIPAL, ello
          implicará la aceptación plena de las condiciones establecidas en los
          Términos y Condiciones y en las Políticas de TIKIPAL GLOBAL SAS. Por
          dicha utilización de TIKIPAL, el Usuario Final se obligará a cumplir
          expresamente con tales Términos y Condiciones y la Política de
          Tratamiento de Datos Personales, no pudiendo alegar el desconocimiento
          de dichos documentos.
        </p>
        <p>
          CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES Y LA
          POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES, LOS CUALES TIENEN
          CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA
          APP Y/O SUS SERVICIOS.
        </p>
        <h2>CAPACIDAD</h2>
        <p>
          Los Servicios sólo están disponibles para personas que tengan
          capacidad legal para contratar. No podrán utilizar los servicios las
          personas que no tengan esa capacidad, los menores de edad o Usuarios
          Finales de Tikipal Global SAS que hayan sido suspendidos temporalmente
          o inhabilitados definitivamente.
        </p>
        <p>
          Los actos que llegaren a realizar menores de edad o personas que no
          tengan la capacidad legal para contratar en Tikipal Global SAS, serán
          responsabilidad de sus padres, tutores o curadores y, por tanto, se
          consideran realizados por éstos en ejercicio de la representación
          legal que ostentan.
        </p>
        <p>
          En el evento en que sea registrada una persona jurídica como Usuario
          Final de Tikipal Global SAS, quien lo haga deberá tener la
          representación legal de tal entidad y la facultad de obligarla de
          acuerdo con los Términos y Condiciones y la Política de Tratamiento de
          Datos Personales.
        </p>
        <h2>REGISTRO Y USO DE TIKIPAL</h2>
        <p>
          Para convertirse en usuario autorizado de Tikipal Global SAS (&quot;el
          Usuario Final&quot;), deberá completar el formulario de inscripción en
          todos sus campos con datos válidos. De esta manera, el Usuario Final
          podrá utilizar los servicios que presta Tikipal Global SAS y adquirir
          los productos y servicios ofrecidos en la plataforma. El futuro
          Usuario Final deberá completar el formulario con su información
          personal de manera exacta, precisa y veraz y asume el compromiso de
          actualizar los Datos Personales conforme resulte necesario.
        </p>
        <p>
          Tikipal Global SAS podrá utilizar diversos medios para identificar a
          sus Usuarios Finales, pero no se responsabiliza por la certeza de los
          Datos Personales suministrados por sus Usuarios Finales. Los Usuarios
          Finales garantizan y responden, en cualquier caso, por la veracidad,
          exactitud, vigencia y autenticidad de los Datos Personales
          suministrados. Tikipal Global SAS se reserva el derecho de solicitar
          algún comprobante y/o dato adicional a efectos de corroborar los Datos
          Personales de sus Usuarios Finales, así como de suspender temporal o
          definitivamente a aquellos Usuarios Finales cuyos datos no hayan
          podido ser confirmados.
        </p>
        <p>
          Cada Usuario Final sólo podrá ser titular de una cuenta en Tikipal
          Global SAS (&quot;la Cuenta&quot;) y accede a ella mediante el ingreso
          de usuario y contraseña personal elegida que le permitirá el acceso
          personalizado, confidencial y seguro. El Usuario Final se obliga a
          mantener la confidencialidad de su Contraseña, la cual es de uso
          personal y su entrega a terceros por parte del Usuario Final, exonera
          a Tikipal Global SAS de responsabilidad en caso de utilización
          indebida, negligente, incorrecta y/o fraudulenta.
        </p>
        <p>
          El Usuario Final será responsable por todas las operaciones efectuadas
          en y desde su Cuenta, pues el acceso a la misma está restringido al
          ingreso y uso de su Contraseña, de conocimiento exclusivo del Usuario
          FInal. El Usuario Final se compromete a notificar a Tikipal Global SAS
          en forma inmediata y por medio idóneo y fehaciente, cualquier uso no
          autorizado de su Cuenta y/o Contraseña, así como el ingreso por
          terceros no autorizados a la misma.
        </p>
        <p>
          Tikipal Global SAS se reserva el derecho de rechazar cualquier
          solicitud de registro, de suspender en forma temporal o inhabilitar
          definitivamente la Cuenta de un Usuario Final sin que esté obligada a
          comunicar o exponer las razones de su decisión y sin que ello genere
          algún derecho a indemnización o resarcimiento por parte de Tikipal
          Global SAS al Usuario Final. En el caso de la suspensión o
          inhabilitación, todas las publicaciones del Usuario Final serán
          removidas de la plataforma.
        </p>
        <h2>MODIFICACIONES</h2>

        <p>
          Tikipal Global SAS se reserva el derecho de modificar o sustituir los
          Términos y Condiciones (o cualquiera de los acuerdos que forman parte
          de estos Términos y Condiciones), en cualquier momento (“las
          Modificaciones”). Las Modificaciones a estos Términos y Condiciones se
          publicarán en la plataforma de Tikipal con un cambio en la fecha de
          actualización, en la parte superior de estos Términos y Condiciones.
          Las Modificaciones entrarán en vigor a los diez (10) días siguientes a
          la fecha de actualización. Es responsabilidad del Usuario Final
          revisar periódicamente estos Términos y Condiciones para saber si
          existen Modificaciones. El uso continuado de los Servicios tras la
          entrada en vigor de cualquier Modificación de los Términos y
          Condiciones constituye una aceptación de tales Modificaciones.
        </p>

        <p>
          Si el Usuario Final no acepta una Modificación de cualquiera de estos
          Términos y Condiciones, deberá cesar el acceso, navegación y cualquier
          otra forma de utilización de los Servicios y contactar a Tikipal
          Global SAS para informar la no aceptación; en tal evento, quedará
          disuelto el vínculo contractual y la Cuenta del Usuario Final quedará
          inhabilitada.
        </p>

        <h2>USUARIO ALIADO</h2>

        <p>
          El Usuario Aliado es toda persona jurídica que, en desarrollo de su
          objeto social, sea propietario de establecimientos de comercio
          dedicados al entretenimiento y al expendio de bebidas alcohólicas y
          alimentos.
        </p>

        <h2>USUARIO FINAL</h2>

        <p>
          El Usuario Final es toda persona natural, mayor de edad que, como
          destinatario final, use los Servicios para la celebración de un
          contrato de compraventa o cualquier otro tipo de contrato lícito, con
          el fin de adquirir bienes o servicios a los Usuarios Aliados o
          directamente a TIKIPAL GLOBAL SAS.
        </p>

        <h2>LOS SERVICIOS</h2>

        <p>
          Los Servicios constituyen una plataforma de tecnología (TIKIPAL GLOBAL
          SAS, dominios de TIKIPAL GLOBAL SAS, cualquier servicio tecnológico
          proporcionado por TIKIPAL GLOBAL SAS, incluyendo las interfaces de
          programación de aplicaciones y cualquier aplicación móvil gratuita que
          TIKIPAL GLOBAL SAS ofrezca, mantenga y suministre) que permite a los
          Usuarios Aliados publicar y promocionar sus productos y servicios
          disponibles en sus establecimientos de comercio (eventos, entradas o
          covers, bebidas, paquetes de consumo o combos, entre otros),
          ofrecerlos y venderlos a los Usuarios Finales, quienes, a su vez,
          pueden adquirirlos a través de TIKIPAL GLOBAL SAS. A no ser que
          TIKIPAL GLOBAL SAS lo acepte mediante un contrato separado por escrito
          con el Usuario Final, los Servicios se ponen a disposición solo para
          uso personal, no comercial.
        </p>

        <p>
          Cada uno de los productos y servicios ofrecidos por el Usuario Aliado
          contendrá los términos y condiciones aplicables para cada uno de
          ellos, tales como cantidad, disponibilidad, marca y presentación,
          entre otros. Será obligación del Usuario Aliado garantizar que el
          producto o servicio ofrecido cumple con la legislación nacional y
          local vigente y demás normas y reglamentos aplicables. TIKIPAL GLOBAL
          SAS se reserva el derecho de actualizar, modificar y/o descontinuar
          los productos o servicios ofrecidos a través de TIKIPAL GLOBAL SAS
          App.
        </p>

        <p>
          TIKIPAL GLOBAL SAS facilita un espacio donde Usuarios Aliados y
          Usuarios Finales pueden efectuar sus transacciones, a través de
          TIKIPAL GLOBAL SAS App; sin embargo, no es el productor, proveedor,
          vendedor distribuidor o agente de los productos, el prestador de los
          servicios, ni el organizador o propietario de los eventos ofrecidos
          por los Usuarios Aliados en TIKIPAL GLOBAL SAS App. Para realizar
          compras de TIKIPAL GLOBAL SAS App, los Usuarios Finales deben seguir
          el procedimiento que se establece a continuación:
        </p>

        <p>Acceder y usar los Servicios con su Cuenta y Contraseña.</p>
        <p>
          Explorar los Servicios en búsqueda de los productos y servicios
          ofrecidos por los Usuarios Aliados que sean de su interés.
        </p>

        <p>
          Seleccionar un producto o servicio. TIKIPAL GLOBAL SAS pone a
          disposición del Usuario Final las características, términos y
          condiciones y valor total de los productos y servicios ofrecidos por
          los Usuarios Aliados, por medio de fotografías y notas de referencia
          que permitan su plena individualización para su análisis detallado por
          parte del Usuario Final.
        </p>

        <p>
          Validar el producto o servicios escogido. El Usuario Aliado exhibirá
          un resumen del producto o servicio en cuanto a sus condiciones
          generales, cantidad, disponibilidad, marca y presentación, entre otros
          para que el Usuario Final confirme su interés en la adquisición del
          producto o servicio seleccionado.
        </p>

        <p>
          Ingresar el producto o servicio al carrito de compras. Este ingreso
          corresponde a la voluntad inequívoca del Usuario Final de adquirir un
          producto o servicio determinado, ya que se ha informado con
          suficiencia de las características del mismo, teniendo la posibilidad
          de adquirirlo o no.
        </p>

        <p>
          Valor. Agotados los pasos precedentes, se pone a diposición del
          Usuario Final el valor a pagar por la compra de los productos o
          servicios ingresados en el carrito de compras. Pagar. El Usuario Final
          realizará el pago del producto o servicio adquirido al Usuario Aliado,
          a través de las plataformas virtuales disponibles en TIKIPAL GLOBAL
          SAS para este fin, con los impuestos aplicables y mediante el método
          de pago de su preferencia y, después de ello, el proveedor de la
          pasarela de pago, le enviará un recibo por correo electrónico.
        </p>

        <p>
          Mis Compras. Realizado el pago de los productos o servicios adquiridos
          a los Usuarios Aliados, cada uno quedará relacionado con un código QR,
          en la sección “Mis Entradas” de TIKIPAL GLOBAL SAS para hacerlo
          efectivo en el establecimiento de comercio o en el evento del Usuario
          Aliado en la fecha establecida para el efecto.
        </p>

        <p>
          Resumen y correo electrónico. Completados los pasos anteriores, se
          exhibe una ventana emergente al Usuario Final con el resumen detallado
          de la transacción, el cual también será enviado al correo electrónico
          suministrado por el Usuario Final al abrir su Cuenta.
        </p>

        <p>
          Redimir los productos o servicios. Los productos o servicios
          adquiridos por el Usuario Final y ubicados en la sección “Mis
          Entradas” de TIKIPAL GLOBAL SAS, con un código QR para cada uno,
          podrán redimirse a través del escaneo del código QR por parte del
          Usuario Aliado y la entrega física de cada producto o servicio
          adquirido. En el evento en que al momento de la redención no se
          encuentre disponible el producto o servicio adquirido, el Usuario
          Final podrá decidir entre las siguientes alternativas: (i) redimir ,
          excluyendo el producto o servicio no disponible, caso en el cual el
          Usuario Final conservará el producto o servicio no disponible para
          redimir en otra fecha, o (ii) comunicarle a algún funcionario del
          Usuario Aliado que desea hacer la redención con un producto o servicio
          sustituto o similar.
        </p>

        <p>
          Por consiguiente, una vez el Usuario Final realiza la compra de
          cualquier producto o servicio ofrecido por un Usuario Aliado y realiza
          su pago, a través de TIKIPAL GLOBAL SAS, se genera un contrato de
          compraventa entre el Usuario Aliado y el Usuario Final. En ese
          sentido, TIKIPAL GLOBAL SAS no forma parte de ese contrato de
          compraventa, no actúa como representante del Usuario Aliado, ni asume
          responsabilidad alguna relacionada con dicho contrato.
        </p>

        <p>
          El Usuario Aliado es responsable por los productos y servicios
          vendidos, a través de TIKIPAL GLOBAL SAS App, y debe prestar
          asistencia frente a las reclamaciones del Usuario Final con respecto a
          los términos y condiciones bajo los cuales fueron ofrecidos los
          productos y servicios por parte del Usuario Aliado en TIKIPAL GLOBAL
          SAS App. No obstante y debido a que TIKIPAL GLOBAL SAS desea
          garantizar al Usuario Final un espacio seguro donde realizar sus
          compras y el ejercicio de cualquier derecho previsto en la ley,
          TIKIPAL GLOBAL SAS pone a disposición su Equipo de Servicio al
          Cliente.
        </p>

        <p>
          Todos los pagos realizados por los Usuarios Finales para la
          adquisición de los productos o servicios ofrecidos por los Usuarios
          Aliados se realizarán a través de TIKIPAL GLOBAL SAS. TIKIPAL GLOBAL
          SAS puede valerse de terceros proveedores para procesar los pagos de
          las transacciones realizadas en TIKIPAL GLOBAL SAS App, actuando como
          representante limitado del Usuario Aliado, con el único fin de
          recaudar los pagos abonados por los Usuarios Finales, como
          consecuencia de la adquisición de los productos o servicios ofrecidos
          por los Usuarios Aliados.
        </p>

        <h2>USO DE LOS SERVICIOS</h2>

        <p>
          TIKIPAL GLOBAL SAS le otorga un derecho personal, no exclusivo,
          intransferible, no sublicenciable, revocable y limitado de acceso y
          uso de los Servicios únicamente para los fines de exploración de los
          Servicios y búsqueda, visualización e inscripción para adquirir los
          productos y servicios ofrecidos por los Usuarios Aliados en TIKIPAL
          GLOBAL SAS App. Sin perjuicio de lo anterior, usted no podrá y no
          permitirá que nadie, directa o indirectamente: (i) copie, modifique,
          reproduzca, traduzca, localice el puerto o cree derivados de cualquier
          parte de los Servicios; (ii) aplique ingeniería inversa, desmonte,
          descompile o trate de descubrir el código fuente o estructura,
          secuencia y organización de todos o de parte de los Servicios; (iii)
          arrendar, revender, distribuir, utilizar los Servicios para fines
          comerciales no contemplados en el presente párrafo o de cualquier otro
          modo explotar los Servicios de manera no autorizada o para un
          propósito no intencionado; (iv) retirar o alterar algún aviso de
          propiedad o etiquetas en los Servicios; o (v) participar en alguna
          actividad que interfiera o perturbe los Servicios. Todos los derechos
          no expresamente otorgados en el presente párrafo son derechos
          reservados.
        </p>

        <h2>DEBERES DE TIKIPAL GLOBAL SAS</h2>

        <p>
          En virtud de los presentes Términos y Condiciones, TIKIPAL GLOBAL SAS,
          se obliga a: (i) indicar las características generales del producto o
          servicio ofrecido por el Usuario Aliado para que sirvan de referencia
          a los Usuarios Finales; (ii) informar suficientemente sobre los medios
          habilitados para que los Usuarios Finales realicen el pago; (iii)
          informar en el momento indicado y con suficiencia los datos de los
          Usuarios Aliados con los cuales los Usuarios Finales han de celebrar
          el contrato de compraventa; (iv) enviar al correo electrónico
          suministrado por el Usuario Final un resumen de las compras y la
          constancia de la transacción; (v) poner a disposición de los Usuarios
          Finales los términos y condiciones de uso de los Servicios de forma
          actualizada; (vi) utilizar los Datos Personales únicamente para los
          fines establecidos en la Política de Tratamiento de Datos Personales;
          (vii) utilizar mecanismos de información y validación durante la
          transacción como ventanas emergentes (Pop Ups), que permitan al
          Usuario Final aceptar o no cada paso del proceso de compra.
        </p>

        <h2>PROMOCIONES</h2>

        <p>
          Las promociones que sean ofrecidas por los Usuarios Aliados en TIKIPAL
          GLOBAL SAS no son necesariamente las mismas que ofrezcan otros canales
          de venta utilizados por los Usuarios Aliados, a menos que se señale
          expresamente lo contrario en TIKIPAL GLOBAL SAS o en la publicidad que
          realicen los Usuarios Aliados para cada promoción. Las promociones y
          actividades promocionales se harán en cumplimiento de las normas
          vigentes y, específicamente, en cumplimiento de la Ley 1480 de 2011 o
          el Estatuto del Consumidor.
        </p>
        <p>
          Además de los Términos y Condiciones aquí establecidos, cuando se
          realicen promociones en diversos medios publicitarios, aplican las
          siguientes condiciones específicas:
        </p>
        <p>El uso del cupón de descuento será completamente gratuito.</p>
        <p>
          Se indicará el valor del cupón, la suma mínima de compra para redimir
          el cupón y las fechas válidas para su redención.
        </p>
        <p>
          El cupón de descuento aplica para compras realizadas a través de
          TIKIPAL GLOBAL SAS.
        </p>
        <p>No es acumulable con otras promociones.</p>
        <p>El cupón de descuento podrá ser usado por una sola vez.</p>
        <p>
          Al hacer compra con el cupón, se entiende la aceptación íntegra de los
          Términos y Condiciones y de estas condiciones particulares.
        </p>

        <h2>DERECHO DE RETRACTO Y REVERSIÓN DEL PAGO</h2>

        <p>
          TIKIPAL GLOBAL SAS, así como los Usuarios Aliados que utilizan TIKIPAL
          GLOBAL SAS para comercializar productos y servicios, ofrecen a los
          Usuarios Finales la posibilidad de retractarse de la compra durante
          los primeros cinco (5) días hábiles después de la compra del producto
          o servicio. El derecho de retracto deberá ser solicitado por medio de
          los canales establecidos en la Política de Tratamiento de Datos
          Personales.
        </p>
        <p>
          El término máximo para ejercer el derecho de retracto será de cinco
          (5) días hábiles contados a partir de la fecha de adquisición de los
          productos o servicios en TIKIPAL GLOBAL SAS y entrega de los códigos
          QR. En el evento en que el producto haya comenzado a consumirse, el
          servicio haya comenzado a prestarse por el Usuario Aliado con el
          consentimiento del Usuario Final o cuando entre el momento en que se
          ejerza el derecho de retracto y la fecha pactada para el inicio de la
          prestación del servicio medien menos de cinco (5) días, el Usuario
          Final no podrá ejercer el derecho de retracto.
        </p>
        <p>
          El Usuario Final podrá solicitar que se reverse el pago de un producto
          o servicio adquirido en TIKIPAL GLOBAL SAS App directamente con su
          entidad bancaria, en caso de que el Usuario Final haya sido objeto de
          fraude, el pago corresponda a una transacción no realizada por el
          Usuario Final o el producto o servicio adquirido no haya sido recibido
          por el Usuario Final.{' '}
        </p>
        <p>
          En caso de que el Usuario Final haya solicitado la reversión del pago
          y se determine mala fe por parte de éste, se cargará definitivamente
          la transacción reclamada y la Superintendencia de Industria y Comercio
          podrá imponerle sanciones hasta de cincuenta (50) salarios mínimos
          legales mensuales vigentes.
        </p>
        <p>
          Para solicitar la reversión del pago, el Usuario Final deberá
          presentar una queja ante TIKIPAL GLOBAL SAS dentro de los cinco (5)
          días hábiles siguientes a la fecha en la que tuvo conocimiento del
          fraude, de la operación no solicitada o se presentó el inconveniente
          con el producto adquirido. En caso que el Usuario Final no sea el
          mismo titular del instrumento de pago, la solicitud de reversión ante
          la entidad bancaria deberá ser presentada por el titular del producto
          financiero.
        </p>
        <p>
          Una vez presentada la queja ante TIKIPAL GLOBAL SAS y la solicitud de
          reversión ante la entidad bancaria emisora del instrumento de pago,
          éstos contarán con un término de quince (15) días hábiles para hacer
          efectiva la reversión.
        </p>

        <h2>REEMBOLSOS</h2>

        <p>
          Los reembolsos de dinero por procesos de retracto se por el mismo
          método de pago inicialmente utilizado. TIKIPAL GLOBAL SAS sólo
          realizará el reembolso del dinero al titular de la cuenta en TIKIPAL
          GLOBAL SAS, una vez el mismo sea aprobado, a través de uno de los
          siguientes métodos:
        </p>
        <p>
          Consignación Bancaria: Para recibir el dinero en la cuenta bancaria,
          el Usuario Final deberá suministrar la siguiente información a TIKIPAL
          GLOBAL SAS: (i) entidad bancaria, (ii) tipo de cuenta, (iii) número de
          cuenta, (iv) nombre completo del titular de la cuenta y (v) número de
          identificación. Este reembolso puede tardar hasta 15 días hábiles.
        </p>
        <p>
          Reversión a Tarjeta de Crédito: Los reembolsos que se realizan por
          este método podrán ser parciales o totales, según sea el caso. TIKIPAL
          GLOBAL SAS realizará la solicitud de reversión en la plataforma de
          pago para que ésta notifique al banco del tarjetahabiente sobre la
          reversión del dinero. A partir de la confirmación de la solicitud, la
          entidad bancaria contará con un plazo máximo de treinta (30) días
          hábiles para tarjetas nacionales y hasta de ciento veinte (120) días
          hábiles para tarjetas internacionales para la aplicación de la
          reversión. El Usuario Final deberá sujetarse a los términos de
          duración del proceso de aplicación estipulados por su banco.
        </p>
        <p>
          En caso de que el titular de la Cuenta de TIKIPAL GLOBAL SAS haya
          utilizado una tarjeta de crédito de una persona diferente, el único
          método de reembolso habilitado será la reversión a la tarjeta de
          crédito utilizada.
        </p>

        <h2>CONTENIDO DE TIKIPAL GLOBAL SAS</h2>

        <p>
          El Usuario Final acepta que todo el material, incluyendo, a título
          enunciativo, datos, texto, contenido editorial, elementos de diseño,
          apariencia, formato, gráficos, imágenes, fotografías, vídeos, música,
          sonidos y demás contenidos, incluidos o entregados a través de los
          Servicios o facilitado por TIKIPAL GLOBAL SAS de otra manera, en
          relación con los Servicios (denominados en conjunto, “el Contenido”),
          se encuentra protegido por derechos de autor, marcas registradas,
          marcas de servicio, secretos comerciales u otros derechos de propiedad
          intelectual y por otros derechos de propiedad y por las leyes. TIKIPAL
          GLOBAL SAS puede ser propietario del Contenido, o bien, partes del
          Contenido pueden estar disponibles para TIKIPAL GLOBAL SAS a través de
          acuerdos celebrados con los Usuarios Aliados u otros terceros. La
          compilación de todo el Contenido incluido o facilitado a través de los
          Servicios es de exclusiva propiedad de TIKIPAL GLOBAL SAS y se
          encuentra protegido por leyes de propiedad intelectual. A excepción de
          lo expresamente autorizado por escrito por TIKIPAL GLOBAL SAS o de lo
          vinculado al uso que el Usuario Final tiene permitido de la
          funcionalidad prevista de los Servicios, usted se compromete a no
          vender, otorgar licencia, arrendar, modificar, distribuir, copiar,
          reproducir, transmitir, exhibir y representar públicamente, publicar,
          adaptar, editar o crear trabajos derivados de cualquier Contenido, o
          publicar cualquier Contenido en cualquier otro sitio web o en un
          entorno informático de red para cualquier propósito.
        </p>
        <p>
          Deberá utilizar el Contenido únicamente para los fines permitidos por
          estos Términos y Condiciones y por la legislación local y nacional,
          otras normas o reglamentos. Todos los derechos no expresamente
          concedidos en estos Términos y Condiciones son derechos reservados.
        </p>

        <h2>SU CONTENIDO</h2>

        <p>
          El Usuario Final reconoce y acepta que si contribuye, proporciona o
          pone a disposición cualquier Contenido en los Servicios («Su
          Contenido»), por el presente documento concede a TIKIPAL GLOBAL SAS
          una licencia y un derecho no exclusivo, mundial, perpetuo, irrevocable
          y libre de regalías, transferible, sublicenciable (a través de
          múltiples niveles) para acceder, utilizar, reproducir, transmitir,
          adaptar, modificar, realizar, exhibir, distribuir, traducir, publicar
          y crear trabajos derivados basados en Su Contenido, en todo o en
          parte, en cualquier medio, con el propósito de operar los Servicios
          (incluyendo servicios promocionales y de comercialización de TIKIPAL
          GLOBAL SAS).
        </p>
        <p>
          No obstante lo anterior, TIKIPAL GLOBAL SAS no reclama y el Usuario
          Final no transmite derecho de propiedad alguno sobre ninguna parte de
          Su Contenido, y nada en estos Términos y Condiciones restringe algún
          derecho que el Usuario Final tenga para el uso y la explotación de Su
          Contenido fuera de los Servicios. El Usuario Final declara y garantiza
          que posee todos los derechos, facultades y autoridad necesarios para
          conceder la licencia anterior, y que todo Su Contenido (i) no
          infringe, viola, se apropia indebidamente o de otro modo se encuentra
          en conflicto con los derechos de terceros; (ii) cumple con todas la
          legislación local y nacional y demás leyes, normas y reglamentos; y
          (iii) no viola los Términos y Condiciones, ni la Política de
          Tratamiento de Datos Personales. Además, Su Contenido debe ser exacto
          y veraz.{' '}
        </p>
        <p>
          TIKIPAL GLOBAL SAS se reserva el derecho a eliminar cualquier parte de
          Su Contenido de los Servicios en cualquier momento en caso de que
          considere, a su discreción, que viola los Términos y Condiciones.
          Además, el Usuario Final acepta que TIKIPAL GLOBAL SAS pueda usar su
          nombre y logotipo (lo haya usted facilitado o no a través de los
          Servicios) con el propósito de identificarle como Usuario Final de
          TIKIPAL GLOBAL SAS, tanto en los Servicios como en la
          comercialización, publicidad y materiales promocionales.
        </p>

        <h2>COMENTARIOS, SUGERENCIAS Y MODIFICACIONES</h2>

        <p>
          El Usuario Final acepta que cualquiera y todas las sugerencias de
          corrección, cambio y modificación de los Servicios y otros comentarios
          y sugerencias (incluyendo, entre otros, las citas de comentarios
          orales o escritos), la información y los informes que usted
          proporcione a TIKIPAL GLOBAL SAS (“los Comentarios”) y todas las
          mejoras, actualizaciones, modificaciones o avances, ya sea realizados,
          creados o desarrollados por TIKIPAL GLOBAL SAS o de otro modo
          relacionados con los Comentarios (“las Mejoras”) son y seguirán siendo
          propiedad de TIKIPAL GLOBAL SAS.{' '}
        </p>
        <p>
          Todos los Comentarios y Mejoras son propiedad única y exclusiva de
          TIKIPAL GLOBAL SAS y TIKIPAL GLOBAL SAS puede usar y divulgar
          Comentarios y/o Mejoras de cualquier forma y para cualquier propósito,
          sin previo aviso ni compensación y sin derecho de retención por parte
          del Usuario Final de propiedad alguna u otro derecho o reclamación.
          Por el presente, el Usuario Final cede a TIKIPAL GLOBAL SAS todos y
          cualquier derecho, título e interés (incluyendo, entre otros,
          cualquier patente, derechos de autor, futuros derechos de autor,
          secretos comerciales, marcas comerciales, conocimientos prácticos
          (show-how y know-how) y cualquier otro derecho de propiedad
          intelectual) que pueda tener con respecto a todos y a cualquiera de
          los Comentarios y Mejoras. El Usuario Final renuncia a cualquier
          derecho moral que pueda tener en cualquier y en todos los Comentarios
          y Mejoras, y consiente cualquier acto que realice TIKIPAL GLOBAL SAS
          que pudiera equivaler a una violación de cualquiera de esos derechos
          morales, en favor de TIKIPAL GLOBAL SAS. A petición de TIKIPAL GLOBAL
          SAS, formalizará todo documento, inscripción o presentación que se le
          solicite para dar cumplimiento efectivo a estas disposiciones.
        </p>

        <h2>COOKIES</h2>

        <p>
          El Usuario Final de TIKIPAL GLOBAL SAS conoce y acepta que TIKIPAL
          GLOBAL SAS podrá utilizar un sistema de seguimiento mediante la
          utilización de cookies (las “Cookies”). Las Cookies son pequeños
          archivos que se instalan en el disco rígido, con una duración limitada
          en el tiempo que ayudan a personalizar los servicios. También
          ofrecemos ciertas funcionalidades que sólo están disponibles mediante
          el empleo de Cookies. Las Cookies se utilizan con el fin de conocer
          los intereses, el comportamiento y la demografía de quienes visitan o
          son Usuarios Finales de TIKIPAL GLOBAL SAS y, de esa forma, comprender
          mejor sus necesidades e intereses y darles un mejor servicio o
          proveerle información relacionada.{' '}
        </p>
        <p>
          También usaremos la información obtenida por intermedio de las Cookies
          para analizar las páginas navegadas por el Usuario Final, las
          búsquedas realizadas, mejorar nuestras iniciativas comerciales y
          promocionales, mostrar publicidad o promociones, banners de interés,
          noticias sobre TIKIPAL GLOBAL SAS, perfeccionar nuestra oferta de
          servicios y personalizar dichos servicios; también podremos utilizar
          Cookies para promover y hacer cumplir las reglas y seguridad de
          TIKIPAL GLOBAL SAS App. TIKIPAL GLOBAL SAS podrá agregar Cookies en
          los correos electrónicos que envíe para medir la efectividad de las
          promociones.
        </p>
        <p>
          Utilizamos adicionalmente las Cookies para que el Usuario Final no
          tenga que introducir su Contraseña frecuentemente durante una sesión
          de navegación, también para contabilizar y corroborar las
          inscripciones, la actividad del Usuario Final y otros conceptos para
          acuerdos comerciales, siempre teniendo como objetivo de la instalación
          de las Cookies, el beneficio del Usuario Final, y no será usado con
          otros fines ajenos a las actividades de TIKIPAL GLOBAL SAS.
        </p>

        <h2>ENLACES Y CUENTAS VINCULADAS</h2>

        <p>
          Los Servicios o los Usuarios Aliados pueden proporcionar enlaces a
          páginas web o recursos. Dado que TIKIPAL GLOBAL SAS no tiene control
          sobre tales sitios web y recursos, el Usuario Final reconoce y acepta
          que TIKIPAL GLOBAL SAS no es responsable por la disponibilidad de
          dichos sitios web o recursos, y no los respalda ni es responsable de
          contenido, publicidad, producto o servicio alguno, ni de otros
          materiales disponibles en dichos sitios o recursos, así como tampoco
          de los posibles daños o pérdidas relacionadas con los mismos, incluso
          si dichos sitios o recursos están conectados con TIKIPAL GLOBAL SAS,
          sus socios, empleador, proveedores de servicios o terceros. Los Datos
          Personales procesados por esos sitios de terceros están sujetos a sus
          propias políticas de tratamiento de datos personales que el Usuario
          Final debe leer cuidadosamente y aceptar, cuando sea aplicable.{' '}
        </p>
        <p>
          Como parte de los Servicios, el Usuario Final puede vincular una
          cuenta que tenga con un servicio de terceros, como redes sociales (por
          ej.: Facebook o Google). Al vincular esa cuenta de terceros, el
          Usuario Final acepta que TIKIPAL GLOBAL SAS puede acceder, almacenar y
          poner a disposición cualquier Contenido que usted haya suministrado y
          almacenado en dicha cuenta del tercero para que esté disponible en o a
          través de los Servicios.
        </p>

        <h2>RENUNCIA</h2>

        <p>
          TIKIPAL GLOBAL SAS realizará sus mejores esfuerzos para asegurar la
          disponibilidad, sin interrupciones, de los Servicios de TIKIPAL GLOBAL
          SAS App, así como la ausencia de errores en cualquier transmisión de
          información que pudiera tener lugar. No obstante, y debido a la
          naturaleza misma de Internet, no es posible garantizar tales extremos,
          por lo cual los Servicios se suministran “tal cual”. De igual forma,
          el acceso a los Servicios de TIKIPAL GLOBAL SAS podrá verse suspendido
          o restringido ocasionalmente a efectos de la realización de
          reparaciones o mantenimientos, o la introducción de nuevos productos o
          servicios procurando limitar la frecuencia y duración de tales
          suspensiones o restricciones.
        </p>
        <p>
          TIKIPAL GLOBAL SAS no hace declaración, ni presta garantía alguna,
          expresa o implícita, relativa a la fiabilidad, idoneidad, puntualidad,
          disponibilidad y calidad de los Servicios. Usted reconoce que TIKIPAL
          GLOBAL SAS no tiene control alguno sobre los productos y servicios
          ofrecidos por los Usuarios Aliados en TIKIPAL GLOBAL SAS y que no
          garantiza la calidad, seguridad o legalidad de los eventos,
          establecimientos, productos y servicios promocionados, la veracidad o
          la exactitud de los contenidos de los Usuarios Finales y los Usuarios
          Aliados, ni de los anuncios o de la capacidad de los Usuarios Finales
          y los Usuarios Aliados para realizar o para completar una transacción.
          En estos Términos y Condiciones no existe disposición que se encuentre
          destinado a excluir o limitar cualquier garantía, derecho o
          responsabilidad que no pueda ser legalmente excluida o limitada. Por
          consiguiente, únicamente se aplicarán aquellas responsabilidades,
          limitaciones y exoneraciones contempladas bajo la ley colombiana.
        </p>

        <h2>LIMITACIÓN DE RESPONSABILIDAD</h2>

        <p>
          TIKIPAL GLOBAL SAS no será responsable por daños indirectos,
          incidentales, especiales, ejemplares, punitivos, emergentes, incluidos
          el lucro cesante, pérdidas de ingresos, de contratos, de datos,
          lesiones personales, corporales o emocionales o el daño a la
          propiedad, ni por perjuicios relativos o en relación con cualquier uso
          de los Servicios. TIKIPAL GLOBAL SAS no será responsable de cualquier
          daño, responsabilidad o pérdida que deriven de : (i) su uso o
          dependencia de los Servicios o (ii) cualquier transacción o relación
          entre el Usuario Final y el Usuario Aliado , así como de la redención
          de los productos o servicios adquiridos en el establecimiento de
          comercio o en el evento del Usuario Aliado. TIKIPAL GLOBAL SAS tampoco
          será responsable de demoras o falta de cumplimiento de sus
          obligaciones derivadas de los Términos y Condiciones si dicha demora o
          falta de cumplimiento fuera atribuible a circunstancias ajenas a su
          control razonable. Esta disposición no afecta al derecho del cliente a
          recibir el producto o la prestación del correspondiente servicio de
          una forma razonable, o a recibir el oportuno reembolso en caso de que
          no sea posible suministrarle tales productos o servicios en un plazo
          razonable por cualquier causa ajena al control razonable de TIKIPAL
          GLOBAL SAS.
        </p>

        <h2>INDEMNIZACIÓN</h2>

        <p>
          El Usuario Final acepta defender y mantener indemnes a TIKIPAL GLOBAL
          SAS, sus filiales y subsidiarias, y a cada uno de sus respectivos
          funcionarios, administradores, representantes, promotores de marcas
          compartidas, licenciantes, asociados en el procesamiento de pagos,
          otros socios y empleados, de cualesquiera y todos los daños (ya sean
          directos, indirectos, incidentales, consecuentes o de otro tipo),
          pérdidas, responsabilidades, costos y gastos (incluyendo, a título
          enunciativo, los honorarios razonables de abogados y contables)
          derivados de cualquier reclamación, demanda, pleito, procedimiento (ya
          sea ante un árbitro, tribunal, mediador u otro) o investigación
          realizada por terceros (una “Reclamación”) originada por o derivada
          de: (i) su violación de estos Términos y Condiciones (ii) su uso
          inadecuado de los Servicios; o (iii) la violación de cualquier ley
          local o nacional, norma o reglamento o de los derechos de terceros.
          TIKIPAL GLOBAL SAS deberá informar al Usuario Final de cualquier
          Reclamación en este sentido.
        </p>

        <h2>EXONERACIÓN</h2>

        <p>
          TIKIPAL GLOBAL SAS brinda un espacio en el que los Usuarios Finales y
          los Usuarios Aliados para que puedan realizar transacciones. Sin
          embargo, TIKIPAL GLOBAL SAS no podría funcionar si se le
          responsabiliza por las acciones u omisiones de los diferentes
          Usuarios, tanto en el ámbito de los Servicios como fuera de él. Por
          tal motivo, como un incentivo para que TIKIPAL GLOBAL SAS autorice el
          acceso y uso de los Servicios, el Usuario Final se compromete a
          exonerar a TIKIPAL GLOBAL SAS, y sus filiales y subsidiarias, y a cada
          uno de sus respectivos funcionarios, administradores, representantes,
          socios y empleados, de todos los daños (ya sean directos, indirectos,
          incidentales, derivados o de otro tipo), pérdidas, responsabilidades,
          costos y gastos de toda clase y naturaleza, conocidos o desconocidos,
          que surjan de o en cualquier forma se encuentren vinculados con
          controversias entre el Usuario Final y los Usuarios Aliados en
          relación con los Servicios o con los productos o servicios adquiridos
          a través de TIKIPAL GLOBAL SAS.
        </p>

        <h2>MARCAS</h2>

        <p>
          Las marcas comerciales, marcas de servicio y logotipos de TIKIPAL
          GLOBAL SAS (“las Marcas de TIKIPAL GLOBAL SAS”) que se usan y se
          exhiben en relación con los Servicios, son marcas comerciales
          registradas y no registradas o marcas de servicio de TIKIPAL GLOBAL
          SAS. Otros nombres de empresa, producto y servicio utilizados en
          relación con los Servicios pueden ser marcas registradas o marcas de
          servicio propiedad de terceros (“las Marcas de Terceros” y,
          denominadas conjuntamente con las Marcas de TIKIPAL GLOBAL SAS, “las
          Marcas”). La oferta de Servicios no puede interpretarse como la
          concesión de cualquier licencia o derecho de uso de las Marcas que se
          exhiben en relación con los Servicios sin el consentimiento previo de
          TIKIPAL GLOBAL SAS, otorgado por escrito en cada caso específico, para
          cada uno de esos usos. Las Marcas no pueden emplearse para
          desprestigiar a TIKIPAL GLOBAL SAS, a terceros o a productos o
          servicios de TIKIPAL GLOBAL SAS o de terceros, ni de cualquier forma
          en la que se pueda dañar la reputación de las Marcas. El uso de
          cualquier Marca como parte de un enlace a o desde cualquier sitio está
          prohibido, a menos que TIKIPAL GLOBAL SAS lo haya aprobado, mediante
          consentimiento previo otorgado por escrito en cada caso específico,
          para cada uno de esos enlaces. La reputación ganada por el uso de
          cualquier Marca de TIKIPAL GLOBAL SAS redundará en beneficio de
          TIKIPAL GLOBAL SAS.
        </p>

        <h2>PATENTES, DERECHOS DE AUTOR</h2>

        <p>
          Un número de patentes emitidas y de patentes pendientes de aprobación
          se aplican a los Servicios. El Contenido (como se define en los
          Términos y Condiciones) de los Servicios también se encuentra
          protegido por derechos de autor de propiedad de TIKIPAL GLOBAL SAS y/o
          de terceros. Si el Usuario Final copia partes de los Servicios, estará
          violando los derechos de patente y los derechos de autor.
        </p>

        <h2>JURISDICCIÓN Y LEY APLICABLE</h2>

        <p>
          Este acuerdo estará regido en todos sus puntos por las leyes vigentes
          en la República de Colombia. Si alguna disposición de estos Términos y
          Condiciones es declarada ilegal, nula o inoponible o, por cualquier
          razón, resulta inaplicables, la misma no afectará la validez de las
          disposiciones restantes.
        </p>
        <p>
          Cualquier controversia derivada del presente acuerdo, su existencia,
          validez, interpretación, alcance o cumplimiento, será sometida a los
          jueces de la República de Colombia.
        </p>
      </div>
      <div className="data-policy">
        <h1>NUESTRAS POLÍTICAS DE TRATAMIENTO DE DATOS PERSONALES</h1>

        <h2>INTRODUCCIÓN</h2>

        <p>
          TIKIPAL GLOBAL SAS es una sociedad colombiana, cuya actividad
          principal es actuar como intermediario entre Usuarios Finales y
          Usuarios Aliados, por medio de un plataforma digital compuesta por una
          aplicación móvil/web, para que los Usuarios Finales ingresen, se
          informen sobre los productos y servicios prestados por los Usuarios
          Aliados y puedan adquirir dichos productos o servicios y efectuar su
          pago de manera electrónica.
        </p>
        <p>
          En cumplimiento de lo establecido en la Ley 1581 de 2021, reglamentada
          por los Decretos 1377 de 2013 y 886 de 2014, mediante el presente
          documento se adopta la Política de Tratamiento de Datos Personales con
          el propósito de proteger la información personal suministrada por
          Usuarios Finales, Usuarios Aliados, empleados, proveedores,
          accionistas y cualquier otra persona natural de la cual TIKIPAL GLOBAL
          SAS obtenga o trate información personal. Por esta razón, TIKIPAL
          GLOBAL SAS toma todas las precauciones para resguardar su información
          personal, utilizando los mecanismos de seguridad informática de
          protección de la información más completos y eficaces.
        </p>
        <p>
          La Política de Tratamiento de Datos Personales tiene como objeto
          proteger el derecho constitucional del Habeas Data que tienen todas
          las personas de conocer, actualizar y rectificar la información que se
          haya recogido y almacenado en la distintas bases de datos de TIKIPAL
          GLOBAL SAS, lo que TIKIPAL GLOBAL SAS puede hacer con dicha
          información, así como dar a conocer los derechos que le asisten a los
          titulares de los datos personales y los canales a través de los cuales
          puede proteger estos derechos.
        </p>
        <p>
          Al obtener una Cuenta y/o navegar en TIKIPAL GLOBAL SAS, los Usuarios
          Finales y visitantes manifiestan su consentimiento inequívoco para que
          se utilice su información personal de acuerdo con la Política de
          Tratamiento de Datos Personales que se describe a continuación.
        </p>
        <p>
          Este documento es parte integrante de los Términos y Condiciones de
          TIKIPAL GLOBAL SAS. Mediante la aceptación de los Términos y
          Condiciones en el momento de la inscripción, el Usuario FInal acepta
          la Política de Tratamiento de Datos Personales aquí contenida.
        </p>

        <h2>DEFINICIONES</h2>

        <p>
          <strong>Autorización:</strong> Consentimiento previo, expreso e
          informado del Titular para llevar a cabo el Tratamiento de Datos
          Personales;
        </p>
        <p>
          <strong>Aviso de Privacidad:</strong> Comunicación verbal o escrita
          generada por el Responsable, dirigida al Titular para el Tratamiento
          de sus Datos Personales, mediante la cual se le informa acerca de la
          existencia de las políticas de Tratamiento de información que le serán
          aplicables, la forma de acceder a las mismas y las finalidades del
          Tratamiento que se pretende dar a los Datos Personales;
        </p>
        <p>
          <strong>Base de Datos Personales:</strong> Conjunto organizado de
          Datos Personales que sea objeto de Tratamiento;
        </p>
        <p>
          <strong>TIKIPAL GLOBAL SAS:</strong> Es la sociedad colombiana TIKIPAL
          GLOBAL SAS;
        </p>
        <p>
          <strong>Dato Personal:</strong> Cualquier información vinculada o que
          pueda asociarse a una o varias personas naturales determinadas o
          determinables;
        </p>
        <p>
          <strong>Dato Público:</strong> Es el dato que no sea semiprivado,
          privado o sensible. Son considerados Datos Públicos, entre otros, los
          datos relativos al estado civil de las personas, a su profesión u
          oficio y a su calidad de comerciante o de servidor público. Por su
          naturaleza, los Datos Públicos pueden estar contenidos, entre otros,
          en registros públicos, documentos públicos, gacetas y boletines
          oficiales y sentencias judiciales debidamente ejecutoriadas que no
          estén sometidas a reserva;
        </p>
        <p>
          <strong>Datos Sensibles:</strong> Se entiende por Datos Sensibles
          aquellos que afectan la intimidad del Titular o cuyo uso indebido
          puede generar su discriminación, tales como aquellos que revelen el
          origen racial o étnico, la orientación política, las convicciones
          religiosas o filosóficas, la pertenencia a sindicatos, organizaciones
          sociales, de derechos humanos o que promueva intereses de cualquier
          partido político o que garanticen los derechos y garantías de partidos
          políticos de oposición, así como los datos relativos a la salud, a la
          vida sexual, y los datos biométricos;
        </p>
        <p>
          <strong>Encargado del Tratamiento:</strong> Persona natural o
          jurídica, pública o privada, que por sí misma o en asocio con otros,
          realice el Tratamiento de Datos Personales por cuenta del Responsable
          del Tratamiento;
        </p>
        <p>
          <strong>Responsable del Tratamiento:</strong> Persona natural o
          jurídica, pública o privada, que por sí misma o en asocio con otros,
          decida sobre la Base de Datos y/o el Tratamiento de los Datos
          Personales;
        </p>
        <p>
          <strong>Titular:</strong> Persona natural cuyos Datos Personales sean
          objeto de Tratamiento;
        </p>
        <p>
          <strong>Transferencia:</strong> La transferencia de datos tiene lugar
          cuando el Responsable y/o Encargado del Tratamiento de Datos
          Personales, ubicado en Colombia, envía la información o los Datos
          Personales a un receptor, que a su vez es Responsable del Tratamiento
          y se encuentra dentro o fuera del país;
        </p>
        <p>
          <strong>Transmisión:</strong> Tratamiento de Datos Personales que
          implica la comunicación de los mismos dentro o fuera del territorio de
          la República de Colombia cuando tenga por objeto la realización de un
          Tratamiento por el Encargado por cuenta del Responsable;
        </p>
        <p>
          <strong>Tratamiento:</strong> Cualquier operación o conjunto de
          operaciones sobre Datos Personales, tales como la recolección,
          almacenamiento, uso, circulación o supresión.
        </p>

        <h2>NORMATIVIDAD MARCO LEGAL</h2>

        <p>
          Constitución Política de Colombia Ley 1581 de 2012 y demás leyes que
          lo modifiquen o reglamenten Decretos Reglamentarios Sentencias de la
          Corte Constitucional C-1011 de 2008 y C-748 de 2011 Circulares
          Externas expedidas por la Superintendencia de Industria y Comercio
        </p>

        <h2>ALCANCE</h2>

        <p>
          Esta política se aplica al Tratamiento de los datos de carácater
          personal registrados en las Bases de Datos Personales de propiedad de
          TIKIPAL GLOBAL SAS que contengan información personal de quienes
          ostentan la calidad de Usuarios Finales, Usuarios Aliados, empleados,
          proveedores, accionistas y, en general, terceros que le hayan
          suministrado sus Datos Personales a TIKIPAL GLOBAL SAS, siempre y
          cuando éstos sean personas naturales de acuerdo con lo dispuesto en la
          ley.
        </p>

        <h2>
          TRATAMIENTO AL QUE SERÁN SOMETIDOS LOS DATOS PERSONALES RECOGIDOS POR
          TIKIPAL GLOBAL SAS
        </h2>

        <p>
          Para suministrar un excelente servicio y para que los usuarios puedan
          realizar operaciones en forma ágil y segura, TIKIPAL GLOBAL SAS
          requiere cierta información de carácter personal, que le permite
          ofrecer a los Usuarios Finales servicios y funcionalidades que se
          adecúen mejor a sus necesidades y personalizar los Servicios para
          hacer que sus experiencias en la aplicación móvil/web/web sean lo más
          cómodas posible. Los Datos Personales respecto de los cuales TIKIPAL
          GLOBAL SAS ostenta el carácter de responsable son almacenados en Bases
          de Datos y serán utilizados para las siguientes
        </p>
        <p>
          Ordenar, catalogar, clasificar, dividir o almacenar los Datos
          Personales dentro de los sistemas y archivos de TIKIPAL GLOBAL SAS;
        </p>
        <p>
          Crear y administrar las cuentas de usuarios en la aplicación
          móvil/web/web de su propiedad;
        </p>
        <p>
          Desarrollar estudios internos sobre los intereses, comportamientos y
          demografía de los Usuarios Finales para comprender mejor sus
          necesidades e intereses y ofrecer mejores servicios o proveerles
          información relacionada;
        </p>
        <p>
          Mejorar nuestras iniciativas comerciales y promocionales y analizar
          las páginas visitadas y las búsquedas realizadas por los Usuarios
          Finales, para mejorar nuestra oferta de contenidos y artículos,
          personalizar dichos contenidos, su presentación y servicios;
        </p>
        <p>
          Enviar información o mensajes por correo electrónico sobre nuevos
          servicios, publicar propaganda o promociones, banners e información de
          interés para los Usuarios Finales, noticias sobre la aplicación
          móvil/web, además de la información expresamente autorizada en la
          sección de preferencias del Usuario Final;
        </p>
        <p>
          Enviar información o avisos por mensajes de texto (SMS) al teléfono
          celular suministrado por el Usuario Final a TIKIPAL GLOBAL SAS. Los
          mensajes de texto podrán contener avisos publicitarios y/o
          promocionales de TIKIPAL GLOBAL SAS y/o terceros, así como información
          sobre los servicios de TIKIPAL GLOBAL SAS, incluyendo en particular
          pero sin limitación, recordatorios de vencimientos de pagos y otras
          obligaciones adquiridas por el uso de los Servicios de TIKIPAL GLOBAL
          SAS;
        </p>
        <p>
          Proveer beneficios comerciales a los Usuarios Finales, ofrecer nuevos
          servicios relacionados con los ofrecidos en la aplicación móvil/web y
          realizar invitaciones a eventos;
        </p>
        <p>
          Permitir la participación de los Usuarios Finales en programas de
          lealtad con beneficios, actividades de mercadeo y promocionales;
        </p>
        <p>
          Incluir un bullet para hablar sobre verificar la propiedad de los
          productos en el momento de la redención para evitar el fraude.
        </p>
        <p>
          Realizar encuestas de satisfacción para evaluar la calidad de los
          productos y servicios ofrecidos a través de la aplicación móvil/web;
        </p>
        <p>
          Hacer gestiones contables, fiscales y administrativas de los Usuarios
          Finales, Usuarios Aliados, empleados, proveedores y accionistas;
        </p>
        <p>
          Dar cumplimiento a las obligaciones de carácter laboral contraídas con
          los empleados y capacitarlos sobre el uso de la aplicación móvil/web;
        </p>
        <p>
          Verificar y obtener referencia de personas naturales o jurídicas
          suministradas por candidatos en procesos de selección de personal;
        </p>
        <p>
          Compartir los Datos Personales con los Usuarios Aliados y proveedores
          de servicios o las empresas de “outsourcing” que contribuyan a mejorar
          o facilitar las operaciones a través de la aplicación móvil/web,
          incluyendo, pero sin limitarse a, servicios de realización de eventos,
          medios de pago, seguros o intermediarios en la gestión de pagos, call
          centers o programas de fidelidad, entre otros. TIKIPAL GLOBAL SAS
          velará porque se cumplan ciertos estándares, mediante la firma de
          acuerdos cuyo objeto sea mantener la confidencialidad y seguridad de
          los Datos Personales de los Usuarios Finales. No obstante, TIKIPAL
          GLOBAL SAS no se hace responsable por el uso indebido de los Datos
          Personales de los Usuarios Finales que hagan estas compañías o sitios
          de Internet.
        </p>
        <p>
          En algunos casos, estos proveedores de servicios serán quienes recojan
          Datos Personales directamente del Usuario Final (por ejemplo, en la
          realización de encuestas o estudios). En tales casos, podrá recibir
          una notificación acerca de la participación de un proveedor de
          servicios en tales actividades, y quedará a discreción del Usuario
          Final la información que quiera brindarle y los usos adicionales que
          los proveedores decidan hacer. En caso de que el Usuario Final
          facilite, por propia iniciativa información adicional a dichos
          prestadores de servicios directamente, podrán usar esta información
          conforme a sus propias políticas de tratamiento de datos personales.
        </p>

        <h2>
          DERECHOS QUE LE ASISTEN A LOS TITULARES DE LA INFORMACIÓN CONTENIDA EN
          LAS BASES DE DATOS DE TIKIPAL GLOBAL SAS
        </h2>

        <p>El Titular de los Datos Personales tiene los siguientes derechos:</p>

        <p>
          a. Conocer, actualizar y rectificar sus Datos Personales frente a
          TIKIPAL GLOBAL SAS, en su condición de Responsable del Tratamiento.
          Para hacer cualquier modificación en los Datos Personales
          suministrados en el momento de la inscripción a TIKIPAL GLOBAL SAS,
          debe ingresarse a la sección “Mi Perfil” y editar.
        </p>
        <p>
          b. Solicitar prueba del autorización otorgada a TIKIPAL GLOBAL SAS
          para el Tratamiento, en su condición de Responsable del Tratamiento.
        </p>
        <p>
          c. Ser informado por TIKIPAL GLOBAL SAS, previa solicitud, respecto
          del uso que le ha dado a sus Datos Personales, así como de las
          modificaciones y actualizaciones de la Política de Tratamiento de
          Datos Personales y sus finalidades.
        </p>
        <p>
          d. Presentar ante la Superintendencia de Industria y Comercio quejas
          por infracciones a la Ley 1581 de 2012 y las demás normas que la
          modifiquen, adicionen o complementen, una vez haya agotado el trámite
          de reclamo ante el Responsable del Tratamiento, según lo dispuestos en
          la presente Política
        </p>
        <p>
          e. Revocar la autorización y/o solicitar la supresión de los Datos
          Personales cuando en el Tratamiento no se respeten los principios,
          derechos y garantías constitucionales y legales. En determinados casos
          en los que se haya solicitado la supresión de los Datos Personales, se
          mantendrán por algún tiempo en las bases de datos de TIKIPAL GLOBAL
          SAS, con la finalidad de resolver disputas o reclamaciones.
        </p>
        <p>
          f. Acceder en forma gratuita a sus Datos Personales que hayan sido
          objeto de Tratamiento.
        </p>
        <p>
          g. Oponerse a que sus Datos Personales sean objeto de Tratamiento.
        </p>

        <h2>
          PROCEDIMIENTO PARA ACCESOS, CONSULTA, RECTIFICACIÓN Y ACTUALIZACIÓN DE
          DATOS PERSONALES
        </h2>

        <p>
          Los Titulares de la información tienen derecho a acceder a sus Datos
          Personales y a los detalles del Tratamiento de los mismos, así como a
          rectificarlos y actualizarlos en caso de ser inexactos o a solicitar
          su eliminación cuando considere que resulten ser excesivos o
          innecesarios para las finalidades que justificaron su obtención u
          oponerse al Tratamiento de los mismos para fines específicos. Las vías
          que se han implementado para garantizar el ejercicio de dichos
          derechos, a través de la presentación de la solicitud respectiva, son:
        </p>

        <p>Correo electrónico: info@tikipal.co</p>
        <p>
          Podrá consultar el texto y contenido de la Política de Tratamiento de
          Datos Personales de TIKIPAL GLOBAL SAS en la página web tikipal.com.co
        </p>
        <p>Por correo físico: Carrera 12 # 140-91</p>
        <p>
          Por vía telefónica: a través del número disponible en el servicio de
          soporte de TIKIPAL GLOBAL SAS.
        </p>
        <p>
          Estos canales podrán ser utilizados por Titulares de la información o
          terceros autorizados por la ley para actuar en su nombre con el objeto
          de ejercer los siguientes derechos:
        </p>
        <p>
          Derecho de Acceso: El Titular de la información podrá consultar de
          forma gratuita sus Datos Personales. Para conocer los Datos Personales
          que son objeto de Tratamiento por parte de TIKIPAL GLOBAL SAS, el
          Titular de la información podrá elevar una solicitud a través de
          cualquiera de la vías de comunicación implementadas, indicando la
          información que desea conocer.
        </p>
        <p>
          La petición será atendida en un término máximo de diez (10) días
          hábiles contados a partir del día siguiente a la fecha de recibo de
          esta. Cuando no fuere posible atender la petición dentro de dicho
          término, este hecho se le informará al solicitante, expresando los
          motivos de la demora y señalando la fecha en que se atenderá su
          consulta, la cual en ningún caso podrá ser superior a los cinco (5)
          días hábiles siguientes al vencimiento del primer término.
        </p>
        <p>
          Peticiones, consultas y reclamos: Cuando el Titular o sus
          causahabientes consideren que la información contenida en una Base de
          Datos debe ser objeto de corrección, actualización o supresión, o
          cuando deba ser revocada por advertirse el presunto incumplimiento de
          cualquiera de los deberes contenidos en la ley, podrá presentar un
          reclamo ante TIKIPAL GLOBAL SAS, el cual será tramitado bajo las
          siguientes reglas:
        </p>
        <p>
          Los derechos de rectificación, actualización, supresión o revocatoria
          del consentimiento, únicamente se podrán ejercer por:
        </p>
        <p>
          El Titular o sus causahabientes, previa acreditación de su identidad.
        </p>
        <p>Su representante, previa acreditación de la representación.</p>
        <p>
          Cuando la solicitud sea formulada por persona distinta del Titular y
          no se acredite que la misma actúa en representación de aquél, se
          tendrá por no presentada. La solicitud de rectificación,
          actualización, supresión o revocatoria del consentimiento debe ser
          presentada a través de los medios habilitados por TIKIPAL GLOBAL SAS y
          contener como mínimo, la siguiente información:
        </p>
        <p>Nombre y apellidos del solicitante y del Titular.</p>
        <p>
          Domicilio y correo electrónico del Titular o la información del lugar
          en donde desea recibir la respuesta. A falta de esta información, la
          respuesta se entregarás en las instalaciones de TIKIPAL GLOBAL SAS.
        </p>
        <p>
          Documentos que acrediten la identidad del solicitante y, en caso dado,
          la de su representante con la respectiva autorización.
        </p>
        <p>
          Descripción clara y precisa de los Datos Personales respecto de los
          cuales el Titular busca ejercer alguno de los derechos y la solicitud
          concreta.
        </p>
        <p>
          En caso dado, otros elementos o documentos que faciliten la
          localización de los Datos Personales.
        </p>
        <p>
          El término máximo para atender la solicitud, queja o reclamo será de
          quince (15) días hábiles contados a partir del día hábil siguiente a
          la fecha de su recibo. Cuando no fuere posible atender el reclamo
          dentro de dicho término, se informará al interesado los motivos de la
          demora y la fecha en que se atenderá su reclamo, la cual en ningún
          caso podrá superar los ocho (8) días hábiles siguientes al vencimiento
          del primer término.
        </p>

        <h2>
          AREA RESPONSABLE DE LA ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS DE
          DATOS PERSONALES
        </h2>

        <p>
          El área responsable de atender las peticiones de acceso,
          rectificación, actualización, supresión de Datos Personales o
          revocatoria del consentimiento o autorización otorgada para el
          Tratamiento de sus Datos Personales y reclamos, es el Área de Atención
          al Cliente de TIKIPAL GLOBAL SAS.
        </p>

        <h2>MANUAL PARA EL TRATAMIENTO DE DATOS PERSONALES</h2>

        <p>
          El presente manual para el Tratamiento de Datos Personales pretende
          garantizar el derecho constitucional que tienen todas las personas a
          conocer, actualizar y rectificar la información que se haya recogido
          sobre ellas en las Bases de Datos o archivos que TIKIPAL GLOBAL SAS
          tenga para las finalidades previstas en la autorización respectiva.
        </p>

        <h2>CAPÍTULO I. DISPOSICIONES GENERALES </h2>
        <p>
          ARTÍCULO 1. OBJETO. Por medio del presente manual se da cumplimiento a
          lo previsto en el literal k) del artículo 17 de la Ley 1581 de 2012,
          que regula los deberes que asisten a los Responsables del Tratamiento
          de Datos Personales, dentro de los cuales se encuentra el de adoptar
          un manual interno de políticas y procedimientos para garantizar el
          adecuado cumplimiento de la ley y en especial, para la atención de
          consultas y reclamos.
        </p>
        <p>
          Así mismo tiene la finalidad de regular los procedimientos de
          recolección, manejo y tratamiento de los datos de carácter personal
          que realiza TIKIPAL GLOBAL SAS a fin de garantizar y proteger el
          derecho fundamental de Habeas Data en el marco de lo establecido en la
          misma ley y sus decretos reglamentarios.
        </p>
        <p>
          ARTÍCULO 2. DEFINICIONES. Para efectos de la aplicación de las reglas
          contenidas en el presente manual, se tomarán las mismas definiciones
          establecidas en el Capítulo I – DEFINICIONES de la presente Política.
        </p>
        <p>
          ARTÍCULO 3. PRINCIPIOS. Los principios que se establecen a
          continuación, constituyen los parámetros generales que serán
          respetados por TIKIPAL GLOBAL SAS en los procesos de acopio, uso y
          tratamiento de Datos Personales.
        </p>

        <p>
          Principio de legalidad: El Tratamiento de los Datos Personales a que
          se refiere la Ley 1581 de 2012 es una actividad reglada que debe
          sujetarse a lo establecido en ella y en las demás disposiciones que la
          desarrollen.
        </p>
        <p>
          Principio de finalidad: El Tratamiento de los Datos Personales
          recogidos por TIKIPAL GLOBAL SAS obedece a una finalidad legítima que
          se informa al Titular.
        </p>
        <p>
          Principio de libertad: El Tratamiento de Datos Personales sólo puede
          ejercerse con el consentimiento, previo, expreso e informado del
          Titular. Los Datos Personales no serán obtenidos o divulgados sin
          previa autorización, o en ausencia de mandato legal o judicial que
          releve el consentimiento.
        </p>
        <p>
          Principio de veracidad o calidad: La información sujeta a Tratamiento
          debe ser veraz, completa, exacta, actualizada, comprobable y
          comprensible. Se prohíbe el Tratamiento de datos parciales,
          incompletos, fraccionados o que induzcan a error.
        </p>
        <p>
          Principio de transparencia: En el Tratamiento de los Datos Personales
          debe garantizarse el derecho del Titular a obtener de TIKIPAL GLOBAL
          SAS en cualquier momento y sin restricciones, información acerca de la
          existencia de datos que le conciernan.
        </p>
        <p>
          Principio de acceso y circulación restringida: Los Datos Personales,
          salvo la información pública, no podrán estar disponibles en Internet
          u otros medios de divulgación o comunicación masiva, salvo que el
          acceso sea técnicamente controlable para brindar un conocimiento
          restringido sólo a los Titulares o terceros autorizados.
        </p>
        <p>
          Principio de seguridad: La información sujeta a Tratamiento por parte
          de TIKIPAL GLOBAL SAS se protege mediante el uso de las medidas
          técnicas, humanas y administrativas que sean necesarias para otorgar
          seguridad a los registros evitando su adulteración, pérdida, consulta,
          uso o acceso no autorizado o fraudulento.
        </p>
        <p>
          Principio de confidencialidad: Todas las personas que al interior de
          TIKIPAL GLOBAL SAS intervengan en el Tratamiento de Datos Personales
          están obligadas a garantizar la reserva de la información, inclusive
          después de finalizada su relación con alguna de las labores que
          comprende el Tratamiento.
        </p>

        <h2>CAPÍTULO II. AUTORIZACIÓN</h2>

        <p>
          ARTÍCULO 4. AUTORIZACIÓN. La recolección, almacenamiento, uso,
          circulación o supresión de Datos Personales por parte de TIKIPAL
          GLOBAL SAS requiere del consentimiento libre, previo, expreso e
          informado del Titular de los mismos. TIKIPAL GLOBAL SAS en su
          condición de Responsable del Tratamiento de Datos Personales, ha
          dispuesto de los mecanismos necesarios para obtener la autorización de
          los Titulares garantizando en todo caso que sea posible verificar el
          otorgamiento de dicha autorización.
        </p>
        <p>
          Todos los visitantes de la página web de propiedad de TIKIPAL GLOBAL
          SAS deben registrarse y autorizar el Tratamiento de sus Datos
          Personales para poder hacer uso de los servicios ofrecidos. Por tanto,
          en cada uno de los sistemas se encuentra una casilla que dice
          “Política de Tratamiento de Datos Personales”, la cual debe ser leída
          y aceptada para poder continuar con el uso de los servicios de TIKIPAL
          GLOBAL SAS.
        </p>
        <p>
          TIKIPAL GLOBAL SAS podrá confirmar los Datos Personales suministrados
          por los Usuarios Finales acudiendo a entidades públicas, compañías
          especializadas o centrales de riesgo, para lo cual el Titular lo
          autoriza expresamente. La información que TIKIPAL GLOBAL SAS obtenga
          de estas entidades será tratada de manera confidencial.
        </p>
        <p>
          ARTÍCULO 5. FORMA Y MECANISMOS PARA OTORGAR LA AUTORIZACIÓN. La
          autorización puede ser otorgada por el Titular por alguna de las
          siguientes formas:
        </p>
        <p>Por escrito;</p>
        <p>De forma oral;</p>
        <p>
          Mediante conductas concluyentes e inequívocas de Titular que permitan
          concluir de forma razonable que otorgó la autorización. En ningún caso
          el silencio podrá asimilarse a una conducta inequívoca.
        </p>
        <p>
          Mediante el almacenamiento automático de los datos de los Usuarios
          Finales, Usuarios Aliados o terceros que acceden a TIKIPAL GLOBAL SAS
          por el uso de cookies, como las URL, el navegador utilizado y la
          dirección IP, entre otros;
        </p>
        <p>Mediante el intercambio de correos electrónicos;</p>
        <p>Mediante eventos realizados por TIKIPAL GLOBAL SAS;</p>
        <p>A través de llamadas telefónicas;</p>
        <p>A través de mensajes de WhatsApp;</p>
        <p>
          A través del acceso a TIKIPAL GLOBAL SAS, creando Cuenta y Contraseña;
        </p>
        <p>A través del acceso a la página web.</p>
        <p>
          La autorización será emitida por TIKIPAL GLOBAL SAS y será puesta a
          disposición del Titular, previo al Tratamiento de sus Datos
          Personales.
        </p>

        <p>
          ARTÍCULO 6. PRUEBA DE LA AUTORIZACIÓN. El área responsable del
          Tratamiento en TIKIPAL GLOBAL SAS deben contar con las medidas
          necesarias para mantener registros de cuándo y cómo se obtuvo
          autorización por parte de los Titulares de Datos Personales para el
          Tratamiento de los mismos.
        </p>
        <p>
          ARTÍCULO 7. AVISO DE PRIVACIDAD: El Aviso de Privacidad es el
          documento físico, electrónico o en cualquier otro formato, que es
          puesto a disposición del Titular para el Tratamiento de sus Datos
          Personales. En este documento se comunica al Titular la información
          relativa a la existencia de las políticas de tratamiento de
          información que le serán aplicables, la forma de acceder a las mismas
          y las características del Tratamiento que se pretende dar a los Datos
          Personales.
        </p>
        <p>
          A efectos de garantizar en todos los casos que la autorización incluya
          la totalidad de elementos que permiten al Titular ejercer en debida
          forma sus derechos, en el Aviso de Privacidad TIKIPAL GLOBAL SAS
          incluirá, al menos, la siguiente información:
        </p>
        <p>
          El Tratamiento al cual serán sometidos los Datos Personales y la
          finalidad del mismo;
        </p>
        <p>
          El carácter facultativo de la respuesta a las preguntas que sean
          hechas, cuando estas versen sobre datos sensibles o sobre los datos de
          las niñas, niños y adolescentes;
        </p>
        <p>Los derechos que le asisten como Titular;</p>
        <p>
          La identificación, dirección física o electrónica y teléfono del
          Responsable del Tratamiento.
        </p>
        <p>
          ARTÍCULO 8. DATOS SENSIBLES: Se consideran como datos sensibles
          aquellos que afectan la intimidad del Titular o cuyo uso indebido
          puede generar su discriminación. Entre ellos se encuentran los que
          revelen de una persona el origen racial o étnico, la orientación
          política, las convicciones religiosas o filosóficas, la pertenencia a
          sindicatos, organizaciones sociales, de derechos humanos o que
          promueva intereses de cualquier partido político o que garanticen los
          derechos y garantías de partidos políticos de oposición, así como los
          datos relativos a la salud, a la vida sexual y los datos biométricos.
        </p>
        <p>
          TIKIPAL GLOBAL SAS se compromete a proteger la privacidad durante el
          procesamiento de sus Datos Personales identificables y sensibles. Por
          lo tanto, en el evento que de manera expresa los Titulares de la
          información autoricen el uso de Datos Sensibles, TIKIPAL GLOBAL SAS se
          obliga a hacer uso de los mismos de conformidad con las reglas
          establecidas en los decretos reglamentarios nacionales.
        </p>
        <p>
          Ninguna actividad podrá condicionarse a que el Titular suministre
          Datos Sensibles.
        </p>

        <h2>CAPITULO III. DERECHOS Y DEBERES</h2>

        <p>
          ARTÍCULO 9. DERECHOS DE LOS TITULARES DE LA INFORMACIÓN. El Titular de
          los Datos Personales tiene los siguientes derechos:
        </p>
        <p>
          Conocer, actualizar y rectificar sus Datos Personales frente a TIKIPAL
          GLOBAL SAS, en su condición de Responsable del Tratamiento.
        </p>
        <p>
          Solicitar prueba de la autorización otorgada a TIKIPAL GLOBAL SAS para
          el Tratamiento, en su condición de Responsable del Tratamiento.
        </p>
        <p>
          Ser informado por TIKIPAL GLOBAL SAS previa solicitud, respecto del
          uso que le ha dado a sus Datos Personales.
        </p>
        <p>
          Presentar ante la Superintendencia de Industria y Comercio quejas por
          infracciones a lo dispuesto en la Ley 1581 de 2012, una vez haya
          agotado el trámite de reclamo ante el Responsable del Tratamiento.
        </p>
        <p>
          Revocar la autorización y/o solicitar la supresión de los Datos
          Personales cuando en el Tratamiento no se respeten los principios,
          derechos y garantías constitucionales y legales.
        </p>
        <p>
          Acceder gratuitamente a sus Datos Personales que hayan sido objeto de
          Tratamiento.
        </p>
        <p>Oponerse a que sus Datos Personales sean objeto de Tratamiento.</p>
        <p>
          ARTÍCULO 10. DEBERES DE TIKIPAL GLOBAL SAS EN RELACIÓN CON EL
          TRATAMIENTO DE LOS DATOS PERSONALES. TIKIPAL GLOBAL SAS reconocerá, en
          todo momento, que los Datos Personales son propiedad de las personas a
          las que se refieren y que sólo ellas pueden decidir sobre los mismos.
          En este sentido, TIKIPAL GLOBAL SAS hará uso de ellos sólo para
          aquellas finalidades para las que se encuentra facultado debidamente.
        </p>
        <p>
          ARTÍCULO 11. RETENCIÓN DE INFORMACIÓN PERSONAL. TIKIPAL GLOBAL SAS
          retendrá información personal obtenida a través de cualquiera de los
          medios definidos el tiempo necesario para proporcionar los servicios e
          información solicitada por usted o de conformidad con el período de
          retención contemplado en las leyes aplicables.
        </p>

        <h2>CAPÍTULO IV. PROCEDIMIENTOS DE ACCESO, CONSULTA Y RECLAMACIÓN</h2>

        <p>
          ARTÍCULO 12. DERECHO DE ACCESO: El poder de disposición o decisión que
          tiene el Titular sobre la información que le concierne, conlleva
          necesariamente el derecho de acceder y conocer si su información
          personal está siendo objeto de Tratamiento, así como el alcance,
          condiciones y generalidades de dicho Tratamiento. De esta manera,
          TIKIPAL GLOBAL SAS garantiza al Titular su derecho de acceso en tres
          vías:
        </p>
        <p>
          Conocimiento sobre la efectiva existencia del Tratamiento a que son
          sometidos sus Datos Personales.
        </p>
        <p>
          Acceso a los Datos Personales que están en posesión de TIKIPAL GLOBAL
          SAS.
        </p>
        <p>
          Conocer las circunstancias esenciales del Tratamiento de sus Datos
          Personales, lo cual se traduce en el deber de TIKIPAL GLOBAL SAS de
          informar al Titular sobre el tipo de Datos Personales tratados y todas
          y cada una de las finalidades que justifican el Tratamiento.
        </p>
        <p>
          PARÁGRAFO: TIKIPAL GLOBAL SAS garantizará el derecho de acceso cuando,
          previa acreditación de la identidad del Titular o personalidad de su
          representante, se ponga a disposición de éste, de manera gratuita, el
          detalle de los Datos Personales a través de medios electrónicos que
          permitan el acceso directo del Titular a ellos.
        </p>
        <p>
          Para consultas cuya periodicidad sea mayor a una por un (1) mes
          calendario, TIKIPAL GLOBAL SAS cobrará únicamente los gastos de envío,
          reproducción o certificación de documentos.
        </p>
        <p>
          ARTÍCULO 13. CONSULTAS. Los Titulares o sus causahabientes podrán
          consultar la información personal del Titular que repose en cualquier
          Base de Datos que administre TIKIPAL GLOBAL SAS. En consecuencia,
          TIKIPAL GLOBAL SAS garantiza el derecho de consulta, suministrando a
          los Titulares, toda la información contenida en nuestras Bases de
          Datos que esté vinculada con la identificación del Titular.
        </p>
        <p>
          Para la atención de solicitudes de consulta de datos personales,
          TIKIPAL GLOBAL SAS garantiza que tiene habilitados medios de
          comunicación tales como el correo electrónico: contacto@TIKIPAL GLOBAL
          SAS.co, la página web www.TIKIPAL GLOBAL SAS.co y, personalmente, en
          la Carrera 7 # 124 – 20 de la ciudad de Bogotá.
        </p>
        <p>
          Las consultas serán atendidas en un término máximo de diez (10) días
          hábiles contados a partir de la fecha de su recibo. Cuando no fuere
          posible atender la consulta dentro de dicho término, se informará al
          interesado antes del vencimiento de los diez (10) días, expresando los
          motivos de la demora y señalando la fecha en que se atenderá su
          consulta, la cual en ningún caso podrá superar los cinco (5) días
          hábiles siguientes al vencimiento del primer plazo.
        </p>
        <p>
          ARTÍCULO 14. RECLAMOS. El Titular o sus causahabientes que consideren
          que la información contenida en una de las bases de datos que tenga
          TIKIPAL GLOBAL SAS deba ser objeto de corrección, actualización o
          supresión, o cuando adviertan el presunto incumplimiento de cualquiera
          de los deberes contenidos en la Ley 1581 de 2012, podrán presentar un
          reclamo ante TIKIPAL GLOBAL SAS, el cual será tramitado bajo las
          siguientes reglas:
        </p>
        <p>
          El reclamo se podrá presentar directamente en la dirección de correo
          electrónico mencionado en la Política de Tratamiento de Datos
          Personales.
        </p>
        <p>
          Si el reclamo recibido no cuenta con información completa que permita
          darle trámite, esto es, con la identificación del Titular, la
          descripción de los hechos que dan lugar al reclamo, la dirección, y
          acompañando los documentos que se quiera hacer valer, se requerirá al
          interesado dentro de los cinco (5) días siguientes a su recepción para
          que subsane las fallas. Transcurridos dos (2) meses desde la fecha del
          requerimiento sin que el solicitante presente la información
          requerida, se entenderá que ha desistido del reclamo.
        </p>
        <p>
          Si por alguna circunstancia TIKIPAL GLOBAL SAS recibe un reclamo sobre
          información personal de cuyo Tratamiento no es Responsable, dará
          traslado a quien corresponda en un término máximo de dos (2) días
          hábiles, en caso de conocerlo, e informará de la situación al
          interesado.
        </p>
        <p>
          Una vez recibido el reclamo completo, se incluirá en la Base de Datos
          que mantiene TIKIPAL GLOBAL SAS una leyenda que diga “reclamo en
          trámite” y el motivo del mismo, en un término no mayor a dos (2) días
          hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea
          decidido y se abstendrá de darle Tratamiento al Dato Personal sobre el
          cual recae el reclamo, salvo autorización previa y expresa del
          Titular.
        </p>
        <p>
          El término máximo para atender el reclamo será de quince (15) días
          hábiles contados a partir del día siguiente a la fecha de su recibo.
          Cuando no fuere posible atenderlo dentro de dicho término, se
          informará al interesado antes del vencimiento del referido plazo los
          motivos de la demora y la fecha en que se atenderá su reclamo, la cual
          en ningún caso podrá superar los ocho (8) días hábiles siguientes al
          vencimiento del primer término.
        </p>
        <p>
          Cuando la solicitud sea formulada por persona distinta del Titular y
          no se acredite que la misma actúa en representación de aquél, ésta se
          tendrá por no presentada.
        </p>
        <p>
          La solicitud de rectificación, actualización o supresión debe ser
          presentada a través de los medios habilitados por TIKIPAL GLOBAL SAS
          señalados en nuestra Política de Tratamiento de Datos Personales y
          contener, como mínimo, la siguiente información:
        </p>
        <p>
          El nombre y domicilio del Titular o cualquier otro medio para recibir
          la respuesta.
        </p>
        <p>
          Los documentos que acrediten la identidad o la personalidad de su
          representante.
        </p>
        <p>
          La descripción clara y precisa de los Datos Personales respecto de los
          cuales el Titular busca ejercer alguno de los derechos.
        </p>
        <p>
          En caso dado, otros elementos o documentos que faciliten la
          localización de los Datos Personales.
        </p>
        <p>
          ARTÍCULO 15. RECTIFICACIÓN Y ACTUALIZACIÓN DE DATOS. TIKIPAL GLOBAL
          SAS tiene la obligación de rectificar y actualizar, a solicitud del
          Titular, la información de éste que resulte ser incompleta o inexacta,
          de conformidad con el procedimiento y los términos arriba señalados.
          Al respecto, se tendrá en cuenta en especial lo siguiente:
        </p>
        <p>
          En las solicitudes de rectificación y actualización de Datos
          Personales el Titular debe indicar las correcciones a realizar y
          aportar la documentación que avale su petición.
        </p>
        <p>
          TIKIPAL GLOBAL SAS tiene plena libertad de habilitar mecanismos que le
          faciliten el ejercicio de este derecho, siempre y cuando éstos
          beneficien al Titular. En consecuencia, se podrán habilitar medios
          electrónicos u otros que considere pertinentes.
        </p>
        <p>
          TIKIPAL GLOBAL SAS podrá establecer formularios, sistemas y otros
          métodos simplificados, los cuales deben ser informados en el Aviso de
          Privacidad y que se pondrán a disposición de los interesados en la
          página web de su propiedad.
        </p>
        <p>
          Cada vez que se ponga a disposición una herramienta nueva para
          facilitar el ejercicio de sus derechos por parte de los Titulares de
          información o modifique las existentes, lo informará a través de su
          página web www.TIKIPAL GLOBAL SAS.co o cualquier otro medio idóneo
          para este fin.
        </p>
        <p>
          ARTÍCULO 16. SUPRESIÓN DE DATOS. El Titular tiene el derecho, en todo
          momento, a solicitar a TIKIPAL GLOBAL SAS la supresión (eliminación)
          de sus Datos Personales cuando:
        </p>
        <p>
          Considere que los mismos no están siendo tratados conforme a los
          principios, deberes y obligaciones previstas en la Ley 1581 de 2012.
        </p>
        <p>
          Hayan dejado de ser necesarios o pertinentes para la finalidad para la
          cual fueron recabados.
        </p>
        <p>
          Se haya superado el periodo necesario para el cumplimiento de los
          fines para los que fueron recolectados.
        </p>
        <p>
          Esta supresión implica la eliminación total o parcial de la
          información personal de acuerdo con lo solicitado por el Titular en
          los registros, archivos, Bases de Datos o Tratamientos realizados por
          TIKIPAL GLOBAL SAS.
        </p>
        <p>
          El derecho de cancelación no es absoluto y podrá ser negado por
          TIKIPAL GLOBAL SAS cuando:
        </p>
        <p>
          El Titular tenga un deber legal o contractual de permanecer en la Base
          de Datos.
        </p>
        <p>
          La eliminación de datos obstaculice actuaciones judiciales o
          administrativas vinculadas a obligaciones fiscales, la investigación y
          persecución de delitos o la actualización de sanciones
          administrativas.
        </p>
        <p>
          Los datos sean necesarios para proteger los intereses jurídicamente
          tutelados del Titular; para realizar una acción en función del interés
          público, o para cumplir con una obligación legalmente adquirida por el
          Titular.
        </p>
        <p>
          En caso de resultar procedente la cancelación de los Datos Personales,
          TIKIPAL GLOBAL SAS realizará operativamente la supresión de tal manera
          que la eliminación no permita la recuperación de la información.
        </p>
        <p>
          En el evento en que TIKIPAL GLOBAL SAS proceda con la supresión de uno
          o varios Datos Personales a petición de su Titular, éste entiende que
          tal supresión significará la pérdida de beneficios comerciales o
          personales que tenía como resultado que estos datos reposaran en las
          Bases de Datos.
        </p>
        <p>
          ARTÍCULO 17. REVOCATORIA DE LA AUTORIZACIÓN. Los Titulares de los
          Datos Personales pueden revocar el consentimiento al Tratamiento de
          sus Datos Personales en cualquier momento, siempre y cuando no lo
          impida una disposición legal o contractual, comunicando de esta
          decisión a TIKIPAL GLOBAL SAS por cualquiera de los medios definidos
          en el presente Manual.
        </p>
        <p>
          Será necesario que el Titular al momento de elevar la solicitud de
          revocatoria del consentimiento otorgado a TIKIPAL GLOBAL SAS, indique
          en ésta si la revocatoria que pretende realizar es total o parcial. En
          la segunda hipótesis se deberá indicar con cuál Tratamiento el Titular
          no está conforme.
        </p>
        <p>
          Habrá casos en que el consentimiento, por su carácter necesario en la
          relación entre Titular y Responsable no podrá ser revocado.
        </p>
        <p>
          Sin perjuicio de lo anterior, en el evento en que TIKIPAL GLOBAL SAS
          proceda con la revocatoria de uno o varios Datos Personales a petición
          de su Titular, éste entiende que tal supresión significará la pérdida
          de beneficios comerciales o personales que tenía como resultado que
          estos datos reposaran en las Bases de Datos.
        </p>
        <p>
          ARTICULO 18. DESTRUCCIÓN DE BASES DE DATOS. TIKIPAL GLOBAL SAS
          procederá a dar destrucción total a las Bases de Datos cuya finalidad
          haya cesado o terminado en un término no superior a tres (3) meses
          contados a partir del momento de terminación o cesación de la
          finalidad. La destrucción de la Base de Datos constará en acta
          suscrita por el Responsable de la Base de Datos correspondiente.
        </p>

        <h2>CAPITULO V. SEGURIDAD DE LA INFORMACIÓN</h2>

        <p>
          ARTÍCULO 19. SEGURIDAD DE LA INFORMACIÓN. TIKIPAL GLOBAL SAS tiene
          implementadas las medidas técnicas, humanas y administrativas
          necesarias para otorgar seguridad a los registros evitando su
          adulteración, pérdida, consulta, uso o acceso no autorizado o
          fraudulento, incluyendo, entre otras medidas, cortafuegos
          (“firewalls”) y Secure Socket Layers (“SSL”). De hecho, tiene un
          Política de Seguridad de los Datos de obligatorio cumplimiento para su
          colaboradores y terceros que, con ocasión de sus funciones, puedan
          tener acceso a las Bases de Datos Personales.
        </p>
        <p>
          La información personal será generalmente almacenada en las Bases de
          Datos de TIKIPAL GLOBAL SAS. En la medida que lo exijan las leyes, si
          su información personal tuviese que ser transferida al extranjero, se
          le dará el aviso correspondiente.
        </p>
        <p>
          ARTÍCULO 20. IMPLEMENTACIÓN DE LAS MEDIDAS DE SEGURIDAD. TIKIPAL
          GLOBAL SAS mantendrá protocolos de seguridad de obligatorio
          cumplimiento para el personal que tenga acceso a los datos de carácter
          personal y a los sistemas de información.
        </p>
        <p>
          El procedimiento deberá considerar, como mínimo, los siguientes
          aspectos:
        </p>
        <p>
          Ámbito de aplicación del procedimiento con especificación detallada de
          los recursos protegidos.
        </p>
        <p>
          Medidas, normas, procedimientos, reglas y estándares encaminados a
          garantizar el nivel de seguridad exigido en la Ley 1581 de 2012.
        </p>
        <p>Funciones y obligaciones del personal.</p>
        <p>
          Estructura de las Bases de Datos de carácter personal y descripción de
          los sistemas de información que los tratan.
        </p>
        <p>
          Procedimiento de notificación, gestión y respuesta ante las
          incidencias.
        </p>
        <p>
          Procedimientos de realización de copias de respaldo y de recuperación
          de los datos.
        </p>
        <p>
          Controles periódicos que se deban realizar para verificar el
          cumplimiento de lo dispuesto en el procedimiento de seguridad que se
          implemente.
        </p>
        <p>
          Medidas a adoptar cuando un soporte o documento vaya a ser
          transportado, desechado o reutilizado.
        </p>
        <p>
          El procedimiento deberá mantenerse actualizado en todo momento y
          deberá ser revisado siempre que se produzcan cambios relevantes en el
          sistema de información o en la organización del mismo.
        </p>
        <p>
          El contenido del procedimiento deberá adecuarse en todo momento a las
          disposiciones vigentes en materia de seguridad de los datos personales
        </p>
        <p>
          ARTÍCULO 21. INTERCAMBIO DE INFORMACIÓN PERSONAL. TIKIPAL GLOBAL SAS
          podría compartir información personal con quienes contrate como
          Encargados del Tratamiento, aquellos terceros que le presten servicios
          de apoyo. Estos terceros sólo pueden usar la información personal
          compartida con ellos para realizar por cuenta de TIKIPAL GLOBAL SAS
          los servicios contratados y están obligados a mantener la
          confidencialidad de la misma.
        </p>
        <p>
          En algunos casos, TIKIPAL GLOBAL SAS podría compartir la información
          personal con terceros que se asocian para proporcionar productos y
          servicios a sus clientes. Si lo hiciese, se le debe solicitar a estos
          aliados que usen la información personal proporcionada en una forma
          consecuente con los fines para los cuales fue originalmente obtenida
          (o para los fines que el Titular hubiese autorizado posteriormente) y
          sólo en la forma contemplada en esta Política y de acuerdo con el
          Aviso de Privacidad y demás normas que regulan la materia.
        </p>
        <p>
          En ciertas ocasiones, TIKIPAL GLOBAL SAS también podría compartir o
          transferir información personal a terceros no relacionados. Por
          ejemplo, podría proporcionar información personal a un tercero (i) por
          petición del Titular; (ii) para cumplir con una exigencia legal o una
          orden de un tribunal; (iii) para investigar un posible delito, como un
          robo de identidad; o (iv) bajo circunstancias similares. En dicho
          caso, se deberá tomar las medidas apropiadas para proteger la
          información personal.
        </p>

        <h2>CAPÍTULO VI. DISPOSICIONES FINALES</h2>
        <p>
          ARTÍCULO 22. TIKIPAL GLOBAL SAS ha designado al area de soporte al
          cliente para atender todo lo relacionado con el régimen de protección
          de Datos Personales, en especial la atención de consultas, reclamos,
          solicitudes de rectificación, modificación o supresión, y dará trámite
          a las solicitudes de los Titulares, para el ejercicio de los derechos
          de acceso, consulta, rectificación, actualización, supresión y
          revocatoria a que se refiere la Ley 1581 de 2012.
        </p>
        <p>
          ARTÍCULO 23. VIGENCIA Y MODIFICACIÓN DE LA POLÍTICA, EL MANUAL O EL
          AVISO DE PRIVACIDAD. La presente Política aplicará a partir del
          [insertar fecha]. TIKIPAL GLOBAL SAS se reserva el derecho de
          actualizar o modificar esta Política y Manual en cualquier momento y
          sin previo aviso, mediante la publicación de la versión revisada de la
          misma en los medios definidos para tal fin. TIKIPAL GLOBAL SAS también
          se reserva el derecho de actualizar o modificar en cualquier momento
          el Aviso de Privacidad de la página web mediante la publicación del
          Aviso modificado en la página web. En caso de modificación de esta
          Política y Manual o del Aviso de Privacidad, la modificación sólo se
          aplicará a la información personal que se obtenga después de publicar
          la Política, Manual o el Aviso de Privacidad modificado en la página
          web www.TIKIPAL GLOBAL SAS.co.
        </p>
        <p>
          Si hacemos cambios materiales en la forma cómo tus Datos Personales
          son administrados, te notificaremos por correo electrónico para que
          puedas tomar una decisión informada respecto si aceptas o no que tus
          Datos Personales sean utilizados de esa forma. Si no aceptas esos
          términos, quedará disuelto el vínculo contractual con TIKIPAL GLOBAL
          SAS y tus Datos Personales no serán usados de otra forma que la que
          fue informada al momento de su recolección.
        </p>
      </div>
      <div className="habeas-data">
        <h2>AUTORIZACIÓN DE TRATAMIENTO DE DATOS PERSONALES</h2>
        <p>
          Para TIKIPAL GLOBAL SAS, sociedad debidamente constituida bajo las
          normas de la República de Colombia, identificada con Nit No.
          901660084-8 (en adelante, “TIKIPAL GLOBAL SAS”), proteger la
          privacidad de sus usuarios es muy importante. Por lo tanto, TIKIPAL
          GLOBAL SAS da estricto cumplimiento a lo dispuesto en la Ley
          Estatutaria 1581 de 2012, reglamentada parcialmente por los Decretos
          1377 de 2013 y Decreto 886 de 2014, normas compiladas en el Decreto
          Único Nacional del Sector Comercio, Industria y Turismo 1074 de 2015 y
          aquellas normas que lo modifiquen. TIKIPAL GLOBAL SAS le informa que
          sus datos serán tratados conforme a la POLÍTICA DE TRATAMIENTO DE
          DATOS PERSONALES que se encuentra publicada tanto en la plataforma
          móvil y en el siguiente link para su consulta
          https://www.TIKIPAL.com.co/politicas-de-privacidad/
        </p>
        <p>
          En este caso TIKIPAL GLOBAL SAS actúa como Responsable del Tratamiento
          de los Datos Personales, por lo que mantendrá almacenados estos datos
          y guardará su confidencialidad atendiendo los requisitos y medidas de
          seguridad exigidos por la ley.
        </p>
        <p>
          El tratamiento que le dará TIKIPAL GLOBAL SAS a los datos personales
          recolectados (nombres y apellidos, número celular, dirección, correo
          electrónico, número de cédula, fecha de nacimiento, género) se
          limitará a la recolección, almacenamiento, uso, circulación mediante
          el uso de servidores que pueden estar ubicados en el exterior de
          acceso exclusivo de TIKIPAL GLOBAL SAS, de su controlante, otras
          subordinadas o de sus proveedores, para las finalidades descritas en
          esta autorización, por medio de las aplicaciones móviles, sitios web y
          otros productos y servicios online. Una vez se cumplan con las
          finalidades del tratamiento para la cual se otorgó autorización por
          parte del titular, los datos personales serán suprimidos de las bases
          de datos de TIKIPAL GLOBAL SAS.
        </p>
        <p>
          Finalidades de Tratamiento: El titular de la información acepta de
          manera expresa, voluntaria e informada el tratamiento de sus datos
          personales y para que estos sean consignados, sean recopilados y
          almacenados en las bases de datos de TIKIPAL GLOBAL SAS conforme a las
          finalidades que a continuación se enuncian:
        </p>
        <p>
          (i) Recabar o recolectar los datos personales e incorporarlos y
          almacenarlos en nuestra base de datos; (ii) Ordenar, catalogar,
          clasificar, dividir o separar la información suministrada; (iii)
          Utilizar los datos suministrados en campañas de comunicación,
          divulgación y promoción u oferta de productos, actividades y/o
          servicios desarrollados como parte de estrategias internas de la
          compañía; (iv) Conservar registros históricos de la compañía y
          mantener contacto con los titulares del dato; (v) Verificar, comprobar
          o validar los datos suministrados; (vi) Estudiar y analizar la
          información entregada para el seguimiento y mejoramiento de los
          productos, el servicio y la atención; (vii) Entregar la información
          recolectada a terceros con los que la compañía contrate el
          almacenamiento y administración de los datos personales, bajo los
          estándares de seguridad y confidencialidad a los cuales TIKIPAL GLOBAL
          SAS está obligada según lo contenido en las leyes pertinentes;
          (viii)Transferir los datos personales a cualquier país o servidor en
          otro país; (ix) Comunicar y permitir el acceso a los datos personales
          suministrados a terceros proveedores de servicios de apoyo general y a
          las personas naturales o jurídicas accionistas de TIKIPAL GLOBAL SAS;
          (x) Recabar, tener, manejar y utilizar la información para realizar
          control y prevención del fraude, control y prevención de lavado de
          activos y financiación del terrorismo; (xi) Celebración, ejecución y
          gestión de las propuestas de negocios y contratos objeto de los
          servicios prestados; (xii) Realizar de conformidad con la ley los
          reportes a centrales de riesgo por incumplimiento de las obligaciones
          financieras derivadas de la relación comercial; (xiii) Envío de
          comunicaciones a través de cualquier canal incluyendo pero, sin
          limitarse a, redes sociales, mensajes de texto, notificaciones push,
          correo electrónico, llamada telefónica, etc., relacionadas con el
          objeto social de TIKIPAL GLOBAL SAS tales como actividades de mercadeo
          y/o cualquier solicitud realizada por el titular a TIKIPAL GLOBAL SAS,
          entre otros; (xiv) Prestar los servicios ofrecidos y/o contratados de
          forma adecuada y con una excelente calidad; (xv) Creación y
          administración de la cuenta del usuario; (xvi) Prestar el
          mantenimiento, desarrollo y/o control de la relación comercial entre
          el usuario y TIKIPAL GLOBAL SAS; (xvii) Proveer a los usuarios de la
          información necesaria, a través de la página Web y/o plataforma de
          contacto, sobre los productos de los oferentes, para formalizar la
          relación de consumo de dichos productos; (xviii) Realizar procesos al
          interior de la empresa, con fines de desarrollo operativo y/o de
          administración de sistemas; (xix) Prestar los servicios de la empresa
          y realizar el seguimiento de acuerdo con las necesidades particulares
          del usuario, con el fin de brindar los servicios y productos adecuados
          para cubrir sus necesidades específicas; (xx) Llevar un registro
          histórico de la información, con fines de satisfacción al usuario,
          desarrollando análisis sobre los intereses y necesidades; brindando de
          esta manera un mejor servicio; (xxi) Realizar estrategias de mercado
          mediante el estudio del comportamiento del usuario frente a las
          ofertas y con ello mejorar en su contenido, personalizando
          presentación y servicio; (xxii) Elaboración de prospecciones
          comerciales y segmentación de mercados; (xxiii) Realizar encuestas de
          satisfacción y ofrecimiento o reconocimiento de beneficios propios de
          nuestro programa de lealtad y servicio postventa, para calificar el
          servicio y la atención por medio de los canales dispuestos para ello;
          (xxiv) Adelantar las actividades necesarias para gestionar las
          solicitudes, quejas y reclamos de los usuarios de la empresa o
          terceros y direccionarlos a las áreas responsables de emitir las
          respuestas correspondientes; (xxv) Presentar reportes ante las
          autoridades de inspección, vigilancia y control, y tramitar los
          requerimientos realizados por entidades administrativas o judiciales;
          (xxvi) Gestión contable, económica, fiscal y administrativa de
          usuarios; (xxvii) Conservación de la información por los términos
          establecidos en la Ley, especialmente el referente a la información de
          los libros y papeles del comerciante que deberá ser almacenada por un
          período de diez (10) años, según lo dispone el artículo 28 de la Ley
          962 de 2005; (xxviii)Transferencia o Transmisión de Datos Personales
          nacional o internacionalmente a terceros con los que TIKIPAL GLOBAL
          SAS desarrolle actividades en cumplimiento de su objeto social.
          Asimismo, se podrá hacer Transmisión o Transferencia a los aliados
          estratégicos de la empresa para que ejecuten actividades de marketing,
          publicidad y promociones asociadas con el objeto social; todo de
          acuerdo con las disposiciones de la normativa colombiana; (xxix)
          Remitir información a los Encargados del Tratamiento para facilitar y
          mejorar la calidad del servicio de TIKIPAL GLOBAL SAS; (xxx) Solicitar
          la autorización de cobro ante las entidades definidas y autorizadas
          para ello.
        </p>
        <p>
          TIKIPAL GLOBAL SAS podrá transmitir o transferir a terceros los datos
          personales de los que es Responsable. Los datos también podrán ser
          transferidos a otras empresas que se encuentren bajo la misma órbita
          de control de TIKIPAL GLOBAL SAS. Dichos terceros podrán estar
          ubicados en Colombia, o en otros países que, a juicio de la
          Superintendencia de Industria y Comercio, garanticen un nivel adecuado
          de protección de datos personales. Igualmente, podrán ser transferidos
          a, Argentina, Brasil, Chile, Costa Rica, Ecuador, Estados Unidos,
          México, Perú, Uruguay. Así mismo, el Titular autoriza a TIKIPAL GLOBAL
          SAS a transferir y transmitir sus datos personales a terceros aliados
          comerciales de TIKIPAL GLOBAL SAS que ofrecen sus productos y
          servicios en la Plataforma de TIKIPAL GLOBAL SAS, con el fin de hacer
          eficiente el contacto entre el Titular y el aliado comercial, en los
          términos del artículo 53 de la Ley 1480 de 2011.
        </p>
        <p>
          Derechos que le asisten al Titular: En caso en que el Titular de
          información otorgue autorización a TIKIPAL GLOBAL SAS para el
          tratamiento y procesamiento de sus datos personales, los titulares de
          información tendrán los siguientes derechos:(i) Conocer, actualizar y
          rectificar y/o suprimir sus datos personales recolectados por TIKIPAL
          GLOBAL SAS. Este derecho se puede ejercer entre otros frente a datos
          parciales, inexactos, incompletos, fraccionados o que induzcan al
          error, (ii) Solicitar prueba de la autorización otorgada a TIKIPAL
          GLOBAL SAS, (iii) Ser informado por TIKIPAL GLOBAL SAS, previa
          solicitud, respecto del uso que le han dado a los datos del titular,
          (iv) Presentar ante la Superintendencia de Industria y Comercio quejas
          por infracciones a lo dispuesto en la ley, (v) Revocar en cualquier
          tiempo la autorización de tratamiento de datos personales, teniendo en
          cuenta los límites legales para ello, (vi) Acceder en forma gratuita a
          los datos del titular contenidos en la base de datos de TIKIPAL GLOBAL
          SAS.
        </p>
        <p>
          Forma de conocer la información del Titular y cómo ejercer los
          derechos: TIKIPAL GLOBAL SAS presenta a elección del Titular las
          siguientes formas de ejercer sus derechos de “Habeas Data”:
        </p>
        <p>
          Atención electrónica: El Titular del dato podrá realizar la solicitud
          a través del modulo de servicio al cliente de la app o en el correo
          contacto@tikipal.com.co
        </p>
        <p>
          Atención escrita: El Titular del dato deberá realizar su requerimiento
          formal en la dirección Carrera 12 # 140-91, Bogotá D.C. Colombia,
          acreditando de forma previa y suficiente su identidad.
        </p>
        <p>
          SIC Facilita: El Titular tiene la posibilidad de acudir al canal
          dispuesto por la Superintendencia de Industria y Comercio.
        </p>
        <p>
          Se podrá acceder a los Datos Personales si se cumple alguna las
          condiciones establecidas a continuación:
        </p>
        <p>
          Ser Titular de la información, acreditando la identidad en forma
          suficiente mediante cualquier medio que TIKIPAL GLOBAL SAS disponga
          para ello.
        </p>
        <p>Ser causahabiente, quien deberá acreditar tal calidad.</p>
        <p>
          Ser el representante/apoderado del Titular de la información, quien
          deberá acreditar tal calidad.
        </p>
      </div>
    </div>
  ) : (
    <div className="policies">
      <div className="terms-and-conditions">
        <h1>TERMS AND CONDITIONS</h1>
        <p>
          This document describes the general terms and conditions (the
          &quot;Terms and Conditions&quot;) applicable to the access and use of
          the services offered (&quot;the Services&quot;) by TIKIPAL GLOBAL SAS,
          a simplified stock company, established in accordance with the laws of
          the Republic of Colombia, identified with NIT No. 901660084-8, whose
          offices at the registered address are located at [address of TIKIPAL
          GLOBAL SAS], through its mobile/web application owned by
          (&quot;TIKIPAL&quot;). Any person who wishes to access and/or use the
          mobile/web application and its services may do so subject to the Terms
          and Conditions, along with all other policies and principles that
          govern TIKIPAL GLOBAL SAS and which are incorporated herein by
          reference. Consequently, all visits and all acts and contracts made in
          TIKIPAL, as well as their legal effects, will be governed by these
          rules and subjected to the legislation applicable in Colombia.
        </p>
        <p>
          The Terms and Conditions and the Personal Data Processing Policy
          contained in this document shall apply and be understood as an
          integral part of all acts and contracts executed or celebrated through
          the offering and commercialization systems included in TIKIPAL between
          the End Users of TIKIPAL and TIKIPAL GLOBAL SAS.
        </p>
        <p>
          The End User must read, understand, and accept all the conditions
          established in the Terms and Conditions and in the Personal Data
          Processing Policy, as well as in the other documents incorporated to
          them by reference, prior to their registration as a user of TIKIPAL.
          If the user makes use of TIKIPAL, this will imply the full acceptance
          of the conditions established in the Terms and Conditions and in the
          Policies of TIKIPAL GLOBAL SAS. By such use of TIKIPAL, the End User
          will be obliged to comply expressly with such Terms and Conditions and
          the Personal Data Processing Policy, not being able to claim ignorance
          of such documents.
        </p>
        <p>
          ANY PERSON WHO DOES NOT ACCEPT THESE TERMS AND CONDITIONS AND THE
          PERSONAL DATA PROCESSING POLICY, WHICH HAVE A MANDATORY AND BINDING
          NATURE, SHOULD REFRAIN FROM USING THE APP AND/OR ITS SERVICES.
        </p>
        <h2>CAPACITY</h2>
        <p>
          The Services are only available to individuals who have legal capacity
          to contract. Individuals who do not have this capacity, minors, or
          Tikipal Global SAS End Users who have been temporarily suspended or
          indefinitely disabled cannot use the services.
        </p>
        <p>
          Acts carried out by minors or individuals without legal capacity to
          contract at Tikipal Global SAS will be the responsibility of their
          parents, guardians, or curators, and therefore, considered made by
          them in the exercise of legal representation they hold.
        </p>
        <p>
          In the event that a legal entity is registered as an End User of
          Tikipal Global SAS, the person who does so must have the legal
          representation of such entity and the authority to bind it according
          to the Terms and Conditions and the Personal Data Processing Policy.
        </p>
        <h2>REGISTRATION AND USE OF TIKIPAL</h2>
        <p>
          To become an authorized user of Tikipal Global SAS (&quot;the End
          User&quot;), you must complete the registration form in all its fields
          with valid data. In this way, the End User will be able to use the
          services provided by Tikipal Global SAS and acquire the products and
          services offered on the platform. The future End User must complete
          the form with their personal information in an accurate, precise, and
          truthful manner and undertakes the commitment to update the Personal
          Data as necessary.
        </p>
        <p>
          Tikipal Global SAS may use various means to identify its End Users,
          but is not responsible for the accuracy of the Personal Data provided
          by its End Users. End Users guarantee and respond, in any case, for
          the truthfulness, accuracy, validity, and authenticity of the Personal
          Data provided. Tikipal Global SAS reserves the right to request some
          proof and/or additional data in order to corroborate the Personal Data
          of its End Users, as well as to temporarily or permanently suspend
          those End Users whose data could not be confirmed.
        </p>
        <p>
          Each End User may only hold one account in Tikipal Global SAS, and
          Tikipal Global SAS reserves the right to temporarily or permanently
          suspend those End Users who have more than one account or who have
          provided false information.
        </p>
        <p>
          Tikipal Global SAS reserves the right to reject any application for
          registration or to cancel a previously accepted registration, without
          being obliged to communicate or explain the reasons for its decision
          and without generating any right to compensation or indemnity.
        </p>
        <h2>MODIFICATION OF THE TERMS AND CONDITIONS</h2>
        <p>
          Tikipal Global SAS may modify the Terms and Conditions at any time by
          making the modified terms public on the platform. All modified terms
          will take effect 10 (ten) days after their publication. Within 5
          (five) days following the publication of the modified terms, the End
          User must communicate his refusal to accept them, otherwise, he will
          be deemed to have accepted the new terms. The modified terms will not
          have retroactive effects.
        </p>
        <h2>PRIVACY POLICY</h2>
        <p>
          The End User must read, understand, and accept the Personal Data
          Processing Policy in order to use Tikipal Global SAS.
        </p>
        <h2>INTELLECTUAL PROPERTY</h2>
        <p>
          Tikipal Global SAS is the owner of all intellectual property rights of
          its platform and the content available on it. The use of the platform
          does not grant the End User any property rights over the platform or
          its content.
        </p>
        <p>
          The designs, logos, trademarks, and other distinctive signs that
          appear on the platform belong to Tikipal Global SAS and are duly
          registered. The use of the platform does not grant the End User any
          right to use such designs, logos, trademarks, and/or distinctive
          signs.
        </p>
        <p>
          All content on the platform, including but not limited to texts,
          software, scripts, graphics, photos, sounds, music, videos,
          interactive features, and the like, as well as their trademarks, trade
          names, or patents, are the property of Tikipal Global SAS.
        </p>
        <p>
          The End User is prohibited from reproducing, distributing, modifying,
          copying, publishing, or using any of the content of the platform
          without the prior written authorization of Tikipal Global SAS.
        </p>
        <h2>LIABILITY</h2>
        <p>
          Tikipal Global SAS will only be liable for damages caused to the End
          User in the event that such damages are the result of a willful act or
          omission on the part of Tikipal Global SAS.
        </p>
        <p>
          Tikipal Global SAS will not be liable for any damages caused to the
          End User as a result of force majeure or fortuitous events.
        </p>
        <p>
          Tikipal Global SAS will not be liable for damages caused by the
          unauthorized actions of third parties.
        </p>
        <h2>DISPUTES</h2>
        <p>
          If a dispute arises between the End User and Tikipal Global SAS, the
          parties agree to submit to the jurisdiction of the ordinary courts of
          the Republic of Colombia.
        </p>
        <h2>APPLICABLE LAW AND JURISDICTION</h2>
        <p>
          The Terms and Conditions, as well as the Personal Data Processing
          Policy, are governed by the laws of the Republic of Colombia.
        </p>
      </div>
      <div className="habeas-data">
        <h2>PERSONAL DATA PROCESSING AUTHORIZATION</h2>
        <p>
          For TIKIPAL GLOBAL SAS, a company duly incorporated under the laws of
          the Republic of Colombia, identified with Tax ID No. 901660084-8
          (hereinafter, “TIKIPAL GLOBAL SAS”), protecting the privacy of its
          users is very important. Therefore, TIKIPAL GLOBAL SAS strictly
          complies with the provisions of the Statutory Law 1581 of 2012,
          partially regulated by Decrees 1377 of 2013 and Decree 886 of 2014,
          norms compiled in the National Single Decree of the Commerce,
          Industry, and Tourism Sector 1074 of 2015 and those that modify it.
          TIKIPAL GLOBAL SAS informs you that your data will be processed in
          accordance with the PERSONAL DATA PROCESSING POLICY that is published
          both on the mobile platform and on the following link for your
          consultation https://www.TIKIPAL.com.co/politicas-de-privacidad/
        </p>
        <p>
          In this case, TIKIPAL GLOBAL SAS acts as the Responsible Party for the
          Processing of Personal Data, therefore it will store these data and
          will keep their confidentiality attending the requirements and
          security measures demanded by law.
        </p>
        <p>
          The processing that TIKIPAL GLOBAL SAS will give to the collected
          personal data (names and surnames, mobile number, address, email, ID
          number, date of birth, gender) will be limited to the collection,
          storage, use, circulation through the use of servers that may be
          located abroad with exclusive access of TIKIPAL GLOBAL SAS, its
          controlling company, other subsidiaries or its providers, for the
          purposes described in this authorization, through mobile applications,
          websites and other online products and services. Once the purposes of
          the processing for which authorization was given by the owner are met,
          the personal data will be deleted from the databases of TIKIPAL GLOBAL
          SAS.
        </p>
        <p>
          Purposes of Processing: The data owner expressly, voluntarily, and
          informedly accepts the processing of his/her personal data and for
          them to be consigned, collected, and stored in the databases of
          TIKIPAL GLOBAL SAS in accordance with the purposes that are listed
          below:
        </p>
        <p>
          (i) Collect or gather personal data and incorporate and store them in
          our database; (ii) Order, catalog, classify, divide, or separate the
          information provided; (iii) Use the data supplied in communication
          campaigns, dissemination and promotion or offer of products,
          activities and/or services developed as part of the company&apos;s
          internal strategies; (iv) Preserve historical records of the company
          and maintain contact with the data owners; (v) Verify, check or
          validate the data supplied; (vi) Study and analyze the information
          delivered for the monitoring and improvement of the products, service,
          and attention; (vii) Deliver the collected information to third
          parties with whom the company contracts the storage and administration
          of the personal data, under the security and confidentiality standards
          to which TIKIPAL GLOBAL SAS is bound as contained in the relevant
          laws; (viii) Transfer personal data to any country or server in
          another country; (ix) Communicate and allow access to the personal
          data supplied to third-party general support service providers and to
          natural or legal persons shareholders of TIKIPAL GLOBAL SAS; (x)
          Collect, hold, manage, and use the information for performing fraud
          control and prevention, money laundering control, and terrorism
          financing prevention; (xi) Celebration, execution, and management of
          the business proposals and contracts object of the services provided;
          (xii) Make legally compliant reports to risk centers for
          non-compliance with the financial obligations derived from the
          commercial relationship; (xiii) Send communications through any
          channel including but not limited to, social networks, text messages,
          push notifications, email, telephone call, etc., related to the
          corporate purpose of TIKIPAL GLOBAL SAS such as marketing activities
          and/or any request made by the owner to TIKIPAL GLOBAL SAS, among
          others; (xiv) Provide the offered and/or contracted services
          adequately and with excellent quality; (xv) Creation and management of
          the user&apos;s account; (xvi) Provide the maintenance, development
          and/or control of the commercial relationship between the user and
          TIKIPAL GLOBAL SAS; (xvii) Provide the users with the necessary
          information, through the Website and/or contact platform, about the
          products of the offerors, to formalize the consumption relationship of
          such products; (xviii) Perform internal processes within the company,
          for operational development and/or system administration; (xix)
          Provide the company&apos;s services and follow up according to the
          user&apos;s particular needs, in order to provide the appropriate
          services and products to meet their specific needs; (xx) Keep a
          historical record of the information, for user satisfaction,
          developing analysis on interests and needs; thus providing better
          service; (xxi) Perform market strategies by studying the user&apos;s
          behavior against the offers and thus improve their content,
          personalizing presentation and service; (xxii) Preparation of
          commercial prospecting and market segmentation; (xxiii) Conduct
          satisfaction surveys and offer or recognition of benefits of our
          loyalty program and after-sales service, to qualify the service and
          attention through the channels provided for this purpose; (xxiv)
          Conduct the necessary activities to manage the requests, complaints,
          and claims of the company&apos;s users or third parties and direct
          them to the responsible areas to issue the corresponding responses;
          (xxv) Present reports to the inspection, surveillance, and control
          authorities, and process the requirements made by administrative or
          judicial entities; (xxvi) Accounting, economic, fiscal, and
          administrative management of users; (xxvii) Preservation of
          information for the terms established in the Law, especially the one
          referring to the information of the books and papers of the merchant
          that must be stored for a period of ten (10) years, as provided by
          article 28 of Law 962 of 2005; (xxviii) Transfer or Transmission of
          Personal Data nationally or internationally to third parties with whom
          TIKIPAL GLOBAL SAS develops activities in compliance with its
          corporate purpose. Likewise, the Transmission or Transfer to the
          company&apos;s strategic allies to execute marketing, advertising, and
          promotions activities associated with the corporate purpose; all in
          accordance with the provisions of Colombian regulations; (xxix) Send
          information to the Data Processors to facilitate and improve the
          quality of the service of TIKIPAL GLOBAL SAS; (xxx) Request the
          authorization for collection before the entities defined and
          authorized for this purpose.
        </p>
        <p>
          TIKIPAL GLOBAL SAS may transmit or transfer to third parties the
          personal data of which it is the Responsible Party. The data may also
          be transferred to other companies that are under the same control of
          TIKIPAL GLOBAL SAS. Such third parties may be located in Colombia, or
          in other countries that, in the opinion of the Superintendence of
          Industry and Commerce, guarantee an adequate level of protection of
          personal data. Likewise, they may be transferred to, Argentina,
          Brazil, Chile, Costa Rica, Ecuador, the United States, Mexico, Peru,
          Uruguay. Also, the Owner authorizes TIKIPAL GLOBAL SAS to transfer and
          transmit their personal data to third-party commercial allies of
          TIKIPAL GLOBAL SAS that offer their products and services on the
          TIKIPAL GLOBAL SAS platform, in order to make efficient contact
          between the Owner and the commercial ally, in the terms of article 53
          of Law 1480 of 2011.
        </p>
        <p>
          Rights of the Owner: In case the data owner gives authorization to
          TIKIPAL GLOBAL SAS for the processing and processing of their personal
          data, the data owners will have the following rights: (i) Know,
          update, and rectify and/or delete their personal data collected by
          TIKIPAL GLOBAL SAS. This right can be exercised, among others, against
          partial, inaccurate, incomplete, fragmented, or misleading data, (ii)
          Request proof of the authorization granted to TIKIPAL GLOBAL SAS,
          (iii) Be informed by TIKIPAL GLOBAL SAS, upon request, regarding the
          use that has been given to the data of the owner, (iv) Submit
          complaints to the Superintendence of Industry and Commerce for
          breaches of the provisions of the law, (v) Revoke at any time the
          authorization for the processing of personal data, taking into account
          the legal limits for this, (vi) Access free of charge to the data of
          the owner contained in the database of TIKIPAL GLOBAL SAS.
        </p>
        <p>
          Way to know the information of the Owner and how to exercise the
          rights: TIKIPAL GLOBAL SAS presents at the choice of the Owner the
          following ways to exercise their &quot;Habeas Data&quot; rights:
        </p>
        <p>
          Electronic attention: The data owner can make the request through the
          customer service module of the app or at the email support.
        </p>
        <p>
          Written attention: The data owner must make his formal request at the
          address Carrera 12 # 140-91, Bogotá D.C. Colombia, accrediting his
          identity in a prior and sufficient manner.
        </p>
        <p>
          SIC Facilita: The Owner has the possibility to go to the channel
          provided by the Superintendence of Industry and Commerce.
        </p>
        <p>
          Access to Personal Data may be obtained if any of the conditions
          established below are met:
        </p>
        <p>
          Be the Owner of the information, accrediting the identity sufficiently
          through any means that TIKIPAL GLOBAL SAS has available for this
          purpose.
        </p>
        <p>Be a successor, who must accredit such quality.</p>
        <p>
          Be the representative/attorney of the Owner of the information, who
          must accredit such quality.
        </p>
        <p>
          Be the representative/attorney of the Owner of the information, who
          must accredit such quality.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionsSection;
